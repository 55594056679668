/** CrimsonLogic UX Practice R2-S2 Foundation CSS Components **/

/** Scroll to Top Style **/

#scroll-to-top {
    position: fixed;
    bottom: -50px;
    right: 20px;
    background: rgba(0, 0, 0, 0.5);
    width: 50px;
    height: 50px;
	text-align: center;
    text-decoration: none;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
	-ms-border-radius: 25px;
	-o-border-radius: 25px;
    border-radius: 25px; 
	opacity: 0;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
	z-index: 500;
}

#scroll-to-top.show {
	bottom: 20px;
	opacity: 1;
}

#scroll-to-top i {
    color: #fff;
    margin: 0;
    position: relative;
    top: 11px;
    font-size: 25px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

@media only screen and (hover: hover) {
	
	#scroll-to-top:hover {
		background: #0772b8;
	}
	
	#scroll-to-top:hover i {
		color: #fff;
		top: 4px;
	}
	
}

@media only screen and (max-width: 575px) {
	
	#scroll-to-top {
		bottom: -40px;
		right: 15px;
		width: 40px;
		height: 40px;
		-webkit-border-radius: 20px;
		-moz-border-radius: 20px;
		-ms-border-radius: 20px;
		-o-border-radius: 20px;
		border-radius: 20px; 
	}
	
	#scroll-to-top.show {
		bottom: 15px;
	}
	
	#scroll-to-top i {
		font-size: 20px;
		top: 9px;
	}
}

/** End of Scroll to Top Style **/

/** Loader Style **/

#loader {
	position: fixed;
	width: 100%;
	height: 100%;
	 background-color: rgba(255, 255, 255, 0.80); 
	z-index: 10000;
	text-align: center;
	-webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.loader-box {
	position: relative;
	width: 100%;
	height: 170px;
	top: 50%;
	margin-top: -85px;
	font-size: 12px;
	font-weight: 700;
	letter-spacing: 3px;
	padding-top: 32px;
}

.loader-object {
	position: relative;
	display: block;
	width: 80px;
	height: 80px;
	margin: 0 auto 14px;
	z-index: 20000;
}

/** End of Loader Style **/

/** Start of Notification Bar Style **/

#notification {
    position: relative;
    display: none;
    text-align: left;
    padding: 10px 0;
    overflow: hidden;
    vertical-align: middle;
	font-size:0.9em;
}

#notification strong {
    display: inline-block;
    padding: 7px 20px;
    background: #d71635;
    color: #fff;
    font-weight: 400;
    border-radius: 20px;
    vertical-align: middle;
    margin-right: 15px;
    position: absolute;
    left: 15px;
    top: 50%;
    margin-top: -19px;
}
#notification strong i{ font-size:24px; vertical-align:middle; }
#notification-close {
	color: #000;
	right: 15px;
	position:absolute;
	top:10px;
	-webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

#notification-close:hover {
	color: #000;
}

#notification i {
	font-size: 1.1em;
}

#notification .container {
    padding-right: 40px;
    position: relative;
    padding-left: 240px;
    padding-top: 10px;
    padding-bottom: 10px;
}
/** End of Notification Bar Style **/

/** Start of Animated Menu Button Style **/

.menu-icon {
    display: inline-block;
    cursor: pointer;
}

.menu-bar1,
.menu-bar2,
.menu-bar3 {
    width: 25px;
    height: 2px;
    background-color: #d71635;
    -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -ms-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.menu-bar1 {
	margin: 0 0 6px;
}

.menu-bar2 {
	margin: 6px 0;
}

.menu-bar3 {
	margin: 6px 0 0;
}

/* Rotate first bar */
.change .menu-bar1 {
    -webkit-transform: rotate(135deg) translate(6px, -6px);
	-moz-transition: rotate(135deg) translate(6px, -6px);
	-ms-transform: rotate(135deg) translate(6px, -6px);
	-o-transform: rotate(135deg) translate(6px, -6px);
    transform: rotate(135deg) translate(6px, -6px);    
}

/* Fade out the second bar */
.change .menu-bar2 {
	-webkit-transform: rotate(-180deg);
	-moz-transform: rotate(-180deg);
	-ms-transform: rotate(-180deg);
	-o-transform: rotate(-180deg);
	transform: rotate(-180deg);
    opacity: 0;
}

/* Rotate last bar */
.change .menu-bar3 {
    -webkit-transform: rotate(225deg) translate(5px, 6px);
	-moz-transform: rotate(225deg) translate(5px, 6px);
	-ms-transform: rotate(225deg) translate(5px, 6px);
	-o-transform: rotate(225deg) translate(5px, 6px);
	transform: rotate(225deg) translate(5px, 6px);
}

/** End of Animated Menu Button Style **/

/** Start of Only-Show and Only-Hide Classes **/
/** Mapped to Bootstrap 3's breakpoints and is use to show (Only-Show) or hide (Only-Hide) items at these breakpoints **/

@media only screen and (max-width: 767px) {

	.only-show-sm,
	.only-show-md,
	.only-show-lg {
		display: none;
	}
	
	.only-hide-xs {
		display: none;
	}
}
	
@media only screen and (min-width: 768px) and (max-width: 991px) {

	.only-show-xs,
	.only-show-md,
	.only-show-lg {
		display: none;
	}
	
	.only-hide-sm {
		display: none;
	}

}

@media only screen and (min-width: 992px) and (max-width: 1199px) {

	.only-show-xs,
	.only-show-sm,
	.only-show-lg {
		display: none;
	}
	
	.only-hide-md {
		display: none;
	}

}

@media only screen and (min-width: 1200px) {

	.only-show-xs,
	.only-show-sm,
	.only-show-md {
		display: none;
	}
	
	.only-hide-lg {
		display: none;
	}

}

/** End of Only-Show and Only-Hide Classes **/

/** Start of Same-Height Class **/
/** For Bootstrap Columns with Equal Vertical Height **/

.same-height {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.same-height > [class*='col-'] {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

/** End of Same-Height Class **/