ul.SMARTListGroup.list-group {
    list-style: disc;
    margin-bottom: 20px;
}

.SMARTListGroup .list-group-item {
    display: list-item;
    margin-left: 30px;
    padding-left: 0px;
    border: 0px;
    background-color: transparent;
    margin-bottom: -10px;
}
