/*************** Styling: Modal - General ***************/
/******original from SMART*******/

/* .modal-title {
    font-size: 14px;
    font-weight: 600;
}

.modal-body {
    font-size: 13px;
}

.modal-content{
    width: auto;
} */

/*************** End Styling: Modal - General ***************/
.modal-dialog {
	max-width: 1000px;
	margin: 1.75rem auto;
}
.modal-content{ border:none;}
.modal-title {
    font-size: 1.5rem;
    color: #005eb8;
}
.modal-body, .modal-header{ padding-left:50px; padding-right:50px; border:none;}
.modal-header {
    padding: 40px 50px;
}

.modal-footer {
    text-align: center;
    justify-content: center;
    border: none;
}

.modal-backdrop {
	background-color: rgba(219,219,219,0.80);
	opacity: 1!important;
}
.card-header-modal {
	padding: 0 ;
	text-align: left;
	background: none;
    border: none;
     cursor: pointer; /*added by devteam */
}
.card-header-modal .card-link {
	display: block;
	background: rgb(0,94,184);
	background: -moz-linear-gradient(90deg, rgba(0,94,184,1) 0%, rgba(34,172,166,1) 100%);
	background: -webkit-linear-gradient(90deg, rgba(0,94,184,1) 0%, rgba(34,172,166,1) 100%);
	background: linear-gradient(90deg, rgba(0,94,184,1) 0%, rgba(34,172,166,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#005eb8", endColorstr="#22aca6", GradientType=1);
	color: #fff;
	padding: 13px;
	border-radius: 5px;
	position: relative;
	padding-left: 50px;
}
.modal-dialog .card-body { 
    padding:20px 0;
    text-align: left;
}
.card-header-modal .card-link::before {
    content: "\f331";
    font-family: "Font Awesome 5 Pro";
    font-size: 24px;
    position: absolute;
    left: 15px;
    top: 50%;
    line-height: 100%;
    margin-top: -12px;
}
.card-header-modal .card-link.collapsed
{ 
    background:#888;
}

.card-header-modal .card-link.collapsed::before {
	content: "\f32d";
    font-family: "Font Awesome 5 Pro";
} 

.faq-qn
{
    font-weight: bold;
}

#accordion .card {
    border: none;
    margin-bottom: 10px;
}
.card-body .jumbotron {
    background: none;
    text-align: left;
    padding: 10px 0;
    margin-bottom: 15px;
    border-bottom: 1px dashed #999;
    border-radius: 0;
    font-size: 14px;
}
.card-body .jumbotron h5 {
    margin: 0;
    font-size: 17px;
}
.card-body .jumbotron p {
    margin: 0;
}

.modal-header .close {
    position: absolute;
    top: 44px;
    border: 1px solid #999;
    padding: 0;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    text-shadow: none;
    color: #999;
    opacity: 1 !important;
    font-size: 16px;
    z-index:9;
}

[dir=ltr] .modal-header .close { 
    right: 50px;
}

[dir=rtl] .modal-header .close { 
    left: 50px;
}

.modal-header {
    padding: 40px 50px 0 50px;
}

.result-footer .btn, .modal-footer .btn {
    padding: 3px 10px;
	margin:0;
}