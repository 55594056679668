@media only screen and (min-width:768px){
/*************** Styling: App - Site Container ***************/
.site-container{
  /* padding-top: 140px; */
  overflow: hidden;
  min-height: 100vh;
}

.no-navdisplay{
  padding-top: 75px;
}
.language-div {
  position: absolute;
  right: 0;
}


/*************** Styling: Content - General ***************/
.body-content {
  margin: 30px 30px 20px;
}

.panel-card .start-card-body .smart-content .body-content{
  margin: 30px 30px 20px;
}

.background-content {
  min-height: 84vh;
  height: 100%;
  padding-top: 75px;
  padding-bottom: 75px;
  overflow: hidden;
  margin-left: -15px;
  margin-right: -15px;
}

/*************** Styling: Profile ***************/
.profile{
  color: #585858;
  display: flex;
  align-self: stretch;
}

.profile a{
  color: #585858;
}

.profile a .dropdown-item:hover{
  background-color: #585858 !important;
  color: #ffffff;
}

.profile .dropdown-item:hover{
  background-color: #585858 !important;
  color: #ffffff;
}

.profile a{
  padding-top: 9px;
}

/*************** Styling: Tabs ***************/


.site-container > .smart-content > .row > .body-content > .tab-nav-tabs .tab-nav-link {
  /* color: rgb(65, 131, 215); */
  font-size: 1rem;
  font-weight: 500;
  margin-right: 0.055rem;
}

.panel-card .smart-content > .row > .body-content > .tab-nav-tabs .tab-nav-link {
  /* color: rgb(65, 131, 215); */
  font-size: 1rem;
  font-weight: 500;
  margin-right: 0.055rem;
  background-color: rgb(248,248,248);
  border-bottom: 1px solid #d2d8db;
}

.smart-content > .row > .body-content > .tab-nav-tabs .tab-nav-link.active {
  color: #495057;
  font-size: 1rem;
  font-weight: 500;
  background-color: #fff;
  /* border-top-color: rgb(65, 131, 215);
  border-top-width: 2px; */
  border-bottom: 0px;
}

/********* Card *********/
.panel-card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #F8F8F8;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0rem;
  margin: auto;
  /* margin-bottom: 75px;
  margin-top: 45px; */
  z-index: 2;
  width: 82%;    
  box-shadow: 0px 0px 40px 2px rgba(0,0,0, .4);
}

/* for success page*/
.site-container > .row > .body-content > .start-card > .start-card-body > .title-heading {
  display: block;
  padding: 20px;
  padding-left: 2.5em;
  padding-right: 2.5em;
  border-bottom: 0.5px dashed;
  border-color: #ddd;
  color: #333;
  background-color: #ffffff;
  margin-top: 0.1rem;
  font-size: 24px;
  font-weight: 400;
}

.site-container > .smart-content > .row > .body-content > .form-content {
  clear: both;
  border-top: 1px solid #3d6ac7;
  border-left: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  background-color: #ffffff;
  color: inherit;
  border-radius: 5px;
  margin-bottom: 30px;
}

.site-container > .smart-content > .row > .body-content > .form-content > .panel-heading > .form-title-active {
  font-weight: 100;
  cursor: pointer;
  font-size: 1.3rem;
  padding: 10px 0px;
  margin: 0;
  margin-left: 40px;
}

.site-container > .smart-content > .row > .body-content > .start-card > .start-card-body > .title-heading {
  display: block;
  padding: 20px;
  padding-left: 2.5em;
  padding-right: 2.5em;
  border-bottom: 0.5px dashed;
  border-color: #ddd;
  color: #333;
  background-color: #ffffff;
  margin-top: 0.1rem;
  font-size: 24px;
  font-weight: 400;
}

.start-card-body-content {
  padding-top: 1.25em;
  padding-bottom: 2em;
  padding-left: 2.3rem;
  padding-right: 2.3em;
}

/*************** Styling: Page Footer ***************/


.panel-card > .start-card-body > .smart-content > .row > .page-footer{
  margin-bottom: 0px;
}

.panel-card > .start-card-body > .row > .page-footer {
  margin-bottom: 0px;
}

.page-footer {
  margin: 0px 0px 20px; /*change this*/
  width: 100%;
}  

.page-card-footer {
  margin-bottom: 20px !important;
}  

/*************** Styling: Sticky Title ***************/

.first.sticky-title{
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 142px;
}

.first.body-title-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px;
  padding-left: 30px;
  font-size: 26px;
  font-weight: 100;
}

/*************** Styling: NavBar - Nav Container ***************/

.smart-navbar{
  display: flex;
  /* width: 100%; */
  /* position: fixed; */
  z-index: 3;
  height: fit-content;
  background-color: #FFFFFF;
  }


/*************** Styling: NavBar - Header ***************/
.navbar-header.row { 
    background: #FFFFFF;
    height: 75px;
}

/*************** Styling: NavBar - Content ***************/
.navbar-item{
  display: inline-flex;
  align-items: center;
  padding: 10px 22px 10px 11px;
  font-size: 18px;
  white-space: nowrap;
  cursor: pointer;
}

.navbar-item-icon {
  font-size: 17px;
  margin: .5rem;
}
  
.layout-2d-container .navbar-item{
  font-size: 19px;
  color: #585858;
}

.layout-2d-container li.navbar-item{
  display: flex;
}

.layout-2d-container .dropdown a.navbar-item{
  display: flex;
}
  
.layout-2d-container .col>.nav{ /* Row css*/
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin-right: -15px;
  margin-left: -15px;
  -ms-flex-positive: 1;
  flex-grow: 1;
}



    [aria-expanded="false"] .navbar-text::after {
      display: inline-block;
      width: 0;
      height: 0;
      margin-left: .255em;
      vertical-align: .155em;
      content: "";
      border-top: .3em solid;
      border-right: .3em solid transparent;
      border-bottom: 0;
      border-left: .3em solid transparent;
      transform: rotate(-90deg);
      transition: all .3s ease;
    }
    
    [aria-expanded="true"] .navbar-text::after {
      display: inline-block;
      width: 0;
      height: 0;
      margin-left: .255em;
      vertical-align: .155em;
      content: "";
      border-top: .3em solid;
      border-right: .3em solid transparent;
      border-bottom: 0;
      border-left: .3em solid transparent;
      transform: rotate(0deg);
      transition: all .3s ease;
    }
    
    [aria-expanded="false"] .dropdownlevel-1 .navbar-text::after {
      display: inline-block;
      width: 0;
      height: 0;
      margin-left: .255em;
      vertical-align: .155em;
      content: "";
      border-top: .3em solid;
      border-right: .3em solid transparent;
      border-bottom: 0;
      border-left: .3em solid transparent;
      transform: rotate(0deg);
      transition: all .3s ease;
    }
    
    [aria-expanded="true"] .dropdownlevel-1 .navbar-text::after {
      display: inline-block;
      width: 0;
      height: 0;
      margin-left: .255em;
      vertical-align: .155em;
      content: "";
      border-top: .3em solid;
      border-right: .3em solid transparent;
      border-bottom: 0;
      border-left: .3em solid transparent;
      transform: rotate(-90deg);
      transition: all .3s ease;
    }
    
    [aria-expanded="false"] .dropdownlevel-2 .navbar-text::after {
      display: inline-block;
      width: 0;
      height: 0;
      margin-left: .255em;
      vertical-align: .155em;
      content: "";
      border-top: .3em solid;
      border-right: .3em solid transparent;
      border-bottom: 0;
      border-left: .3em solid transparent;
      transform: rotate(0deg);
      transition: all .3s ease;
    }
    
    [aria-expanded="true"] .dropdownlevel-2 .navbar-text::after {
      display: inline-block;
      width: 0;
      height: 0;
      margin-left: .255em;
      vertical-align: .155em;
      content: "";
      border-top: .3em solid;
      border-right: .3em solid transparent;
      border-bottom: 0;
      border-left: .3em solid transparent;
      transform: rotate(-90deg);
      transition: all .3s ease;
    }
    
    [aria-expanded="false"] .dropdownlevel-3 .navbar-text::after {
      display: inline-block;
      width: 0;
      height: 0;
      margin-left: .255em;
      vertical-align: .155em;
      content: "";
      border-top: .3em solid;
      border-right: .3em solid transparent;
      border-bottom: 0;
      border-left: .3em solid transparent;
      transform: rotate(0deg);
      transition: all .3s ease;
    }
    
    [aria-expanded="true"] .dropdownlevel-3 .navbar-text::after {
      display: inline-block;
      width: 0;
      height: 0;
      margin-left: .255em;
      vertical-align: .155em;
      content: "";
      border-top: .3em solid;
      border-right: .3em solid transparent;
      border-bottom: 0;
      border-left: .3em solid transparent;
      transform: rotate(-90deg);
      transition: all .3s ease;
    }
    
    

      /*************** Styling: NavBar - Footer ***************/

      .container-fluid > .row > .site-container > .navbar-footer {
        margin-top: auto;
        background-color: transparent;
        /* color: #011D75; */
        font-size: .9em;
        padding: 0px;
      }

      .navbar-footer {
        position: absolute;
        text-align: center;
        background-color: transparent;
        font-size: .9rem;
        left: 0;
        bottom: 0;
        width: 100%;
        margin-bottom: 20px;
        z-index: 1;
      }

      .nav-container{ /*for mobile responsive collapse to work smoothly*/
        width: 100%;
        font-family: 'Helvetica', sans-serif;
      }
      .nav-container{
        z-index: -1;
        height: fit-content;
      }

      
  .dropdown.show > .navbar-header-dropdown.dropdown-menu.show >
  .dropright.dropdown .navbar-item.dropdownlevel-1.nav-item{
    padding-top: 3px;
    padding-bottom: 3px;
  }

.layout-2d {
  display: flex;
  width: 100%;
  /* position: fixed; */
  z-index: 3;
  /* height: 140px; */
  background-color: lightsteelblue;
  /* border-bottom: 3px solid #3d6ac7; */
  }

.no-navdisplay-layout {
  height: 77px;
  border-bottom: 3px solid #3d6ac7;
}

.layout-2d-header{
    background: #FFFFFF;
    height: 75px;
}

.layout-2d-header .nav-header-logo {
  padding-left: 35px;
  display: flex;
  height: 35px;
  align-self: center;
}

.layout-2d-container {
    height: 62px;
    white-space: nowrap;
    flex-wrap: nowrap;
    background-color: #F0F0F0;
    align-items: stretch;
    z-index: -1;
}
.layout-2d-container .nav a {       
  font-size: 19px;
  color: rgba(0, 0, 0, 0.65) 
}
.layout-2d-container .navbar-item.active {
  text-decoration: none;
  color: #FFFFFF;
  background-color: #3d6ac7;
}
.layout-2d-container .toggle-dropdown{
  height: 100%;
}
.layout-2d-container .navbar-item {
  text-decoration: none;
  white-space: nowrap;
  height: 100%;
  align-items: center;
  display: flex;
  font-size: 19px;   
}
/* .layout-2d-container .navbar-item:hover {
  text-decoration: none;
  color: #FFFFFF;
  background-color: #585858 !important;
} */

.layout-2d-container .navbar-item i {
  padding-right: 8px;
  padding-left: 8px;
}
.layout-2d-container .navbar-item svg {
  font-size: 17px;
  display: flex;
}
.layout-2d-container .profile .dropdown-item{
  font-size: 15px;
}
.layout-2d-container .navbar-header-dropdown{
  padding: .2rem 0;
  border-radius: .1rem;
}
.layout-2d-container .dropdownlevel-1,.layout-2d-container  .dropdownlevel-2,.layout-2d-container  .dropdownlevel-3{
  color: #585858;
  padding-top: 8px;
  padding-bottom: 8px;
}
.layout-2d-container .row {
  height: 100%
}
}
