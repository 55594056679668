.carousel-indicators {
    /* position: absolute;
    right: 0;
    bottom: 10px;
    left: 0;
    z-index: 2;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none; */

    position: absolute;
    bottom: 10px;
    left: 50%;
    z-index: 15;
    width: 60%;
    padding-left: 0;
    margin-left: -30%;
    text-align: center;
    list-style: none;
}

/* .carousel-img, .carousel-item{
    max-height: 363px; 
    width: 100%;
    object-fit: cover;
}
/* @media only screen and (max-width: 576px) {
    .carousel-img, .carousel-item{
        height: 150px;  /*required if your images are of different sizes. if cropped nicely to the same size, can remove to better customise
        width: 100%;
        object-fit: cover;
    }
} */
.carousel-indicators li {
    border: none !important;
    background: #ccc;
    height: 18px;
    width: 18px;
    margin: 3px !important;
}
.carousel-caption{
    background-color: rgba(0, 0, 0, 0.7);
    text-align: inherit;
    line-height: 22px;
    border-width: 0px;
    margin: 0px;
    padding: 20px;
    letter-spacing: 0px;
    font-weight: 300;
    font-size: 15px;
    white-space: normal;
    min-height: 0px;
    min-width: 500px;
    max-height: none;
    max-width: 500px;
    opacity: 1;
    background-position: 50% 0;
    z-index: 0;
}

.separator {
    width: 100%;
    margin-bottom: 15px;
    position: relative;
    height: 1px;
}