.bls-dashboard {
    margin: 30px 30px 300px
}

.correspondence-cell{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.correspondence-cell~.correspondence-cell::before {
    content: '';
    position: relative;
    background-color: #888888;
    display: block;
    height: 1px;
    width: 100%;
    top: 0;
    left: 0%;
    margin-top: 2px;
    margin-bottom: 2px;
}

/* Advanced Search */
.advanced-search{
    float: right;
    padding-top: 7px;
    cursor: pointer;
}

@media only screen and (max-width: 992px){
    
    .advanced-search{
        margin-bottom: 10px;
    }
}