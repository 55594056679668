.custom .container{
    font-family: "Roboto", sans-serif;
    max-width: 1170px;
}

.panel-card{
    background-color: #ffffff !important;
}

.greyBackground .container{
    padding-bottom: 5px;
    padding-top: 5px;
}
.news-content h3{
    font-size: 24px;
}
.news-content .btn{
    font-size: 15px;
}
.greyBackground{
    background-color: #ebebeb;
    padding: 0px;
}
.services-body{
    padding-top: 4px;
    padding-right: 0px;
    padding-left: 9px;
}
.services-body, .services-title, .services-subtitle{
    color: #ffffff;
}
.services-img{
    width: 80px;
    margin-bottom: 10px;
    margin-top: 20px;
    margin-left: 9px;
}
.services-title{
    font-size: 30px;
    font-weight: 700;
    line-height: 22px;
}
.services-subtitle{
    font-size: 20px;
}
.services-text{
    padding-top: 15px;
}
.services-text a{
    color: white;
    text-decoration: none;
}
.services-text a:hover{
    color: white;
    text-decoration: underline;
}
.services-text ul{
    padding-left: 1.25em;
}
.newsSection{
    padding-top: 40px;
}
.blue-title{
    color: #3697d9;
}
.separator{
    width: 100%;
    margin-bottom: 15px;
    position: relative;
    height: 1px;
}
.separator:after{
    height: 1px;
    background: #d1d1d1;
    background: -moz-linear-gradient(left, rgba(0, 0, 0, 0.18) 0%, rgba(0, 0, 0, 0.12) 35%, rgba(0, 0, 0, 0.05) 70%, transparent 100%);
    background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(0, 0, 0, 0.18)), color-stop(35%, rgba(0, 0, 0, 0.12)), color-stop(70%, rgba(0, 0, 0, 0.05)), color-stop(100%, transparent));
    background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.18) 0%, rgba(0, 0, 0, 0.12) 35%, rgba(0, 0, 0, 0.05) 70%, transparent 100%);
    background: -o-linear-gradient(left, rgba(0, 0, 0, 0.18) 0%, rgba(0, 0, 0, 0.12) 35%, rgba(0, 0, 0, 0.05) 70%, transparent 100%);
    background: -ms-linear-gradient(left, rgba(0, 0, 0, 0.18) 0%, rgba(0, 0, 0, 0.12) 35%, rgba(0, 0, 0, 0.05) 70%, transparent 100%);
    background: linear-gradient(to right, rgba(0, 0, 0, 0.18) 0%, rgba(0, 0, 0, 0.12) 35%, rgba(0, 0, 0, 0.05) 70%, transparent 100%);
    position: absolute;
    bottom: -1px;
    left: 0px;
    content: "";
    width: 100%;
}
.article-body{
    font-size: 15px;
    line-height: 20px;
}
.article-title{
    font-size: 15px;
}
.article-title a{
    font-size: 15px;
    color: #333333;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 1.2;
}
.article-title a:hover {
    color: #338dca;
    text-decoration: underline;
}
.article-subtitle{
    font-size: 85%;
    color: #777777;
}
.article-text{
    font-size: 85%;
    color: #777777;
}
.pr-10 {
    padding-left: 5px;
    padding-right: 10px;
}
.crimson-logo{
    height: 130px;
    width: 320px;
    margin-top: 40px;
}
.center-block{
    display: block;
    margin-right: auto;
    margin-left: auto;
}
.smart-logo{
    height: 130px;
    width: 320px;
}
h4{
    color: #333333;
    line-height: 1.2;
    font-size: 19px;
}
.logo-area{
    padding-top: 80px;
    padding-bottom: 40px;
}
.card img{
    flex-shrink: 0;
}
.dashboardSearch {
    padding: 0%;
    background-image: url("/images/Trianglify1.2.png");
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    display: flex;
    margin: 0px -15px;
    justify-content: center;
}

.scrollToTop {
    color: #ffffff;
    position: fixed;
    bottom: 5px;
    right: 5px;
    width: 50px;
    height: 50px;
    text-align: center;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1005;
    /* -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out; */
    filter: alpha(opacity=0);
    margin-bottom: 29px;
    border-radius: 50px;
}

.scrollToTop svg {
    font-size: 38px;
    margin-top: 4px;
}

@media only screen and (max-width: 575px){
    .scrollToTop svg {
        font-size: 20px;
        margin-top: 9px;
    }
    .scrollToTop{
        width: 40px;
        height: 40px;
    }
}

.scrollToTop:hover {
    background-color: rgba(0, 0, 0, 0.6);
}

@media (max-width: 320px){
    .container {
        width: 100%;
    }
    .card{
        display: initial;
    }
    .dashboardSearch {
        padding: 0%;
        background-image: url("/images/Trianglify1.2.png");
        background-repeat: no-repeat;
        background-size: 100% auto;
        display: flex;
        margin: 0px -15px;
        justify-content: center; 
    }
}

@media(max-width: 500px){
    .carousel{
        margin: 15px 0px;
    }
    .news-content .news-child{
        padding-top: 15px;
    }
    .panel-services{
        min-height: 198px !important;
        display: inherit !important;
    }
    .col-xs-7 {
        width: 57.5555%;
        flex: 0 0 50%;
        float: left;
    }
    .col-xs-5{
        width: 42.4445%;
        flex: 0 0 23%;
        float: left;
    }
    .services-title, .services-subtitle{
        color: #ffffff;
        font-size: 20px;
    }
    .services-text{
        color: #ffffff;
        padding-left: 3px;
        font-size: 14px;
    }
    .greyBackground{
        padding-left: 15px;
        padding-right: 15px;
    }
    .dashboardSearch {
        padding: 0%;
        background-image: url("/images/Trianglify1.2.png");
        background-repeat: no-repeat;
        background-size: 100% auto;
        display: flex;
        margin: 0px -15px;
        justify-content: center;
    }
}

@media (max-width: 992px){
    .panel-services {
        min-height: 368px;
        display: inherit;
    }
    .news-content .news-child{
        padding-top: 15px;
    }
    #sidebarBtn {
        display: inherit;
        text-align: right;
        margin: 10px 0 15px;
        padding-right: 0px;
    }

    .navbar-footer {
        display: inherit;
    }
    .mb-20 .nav{
        display: inherit;
    }
    .col-xs-6{
        width:50%;
        float: left;
    }
    .dashboardSearch {
        padding-right: inherit;
        padding-left: inherit;
        background-image: url("/images/Trianglify1.2.png");
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        margin: 0px -15px;
        justify-content: center;  
    }
    .greyBackground .container{
        padding-bottom: 5px;
        padding-top: 18px;
    }

    .scrollToTop{
        margin-bottom: 15px;
        margin-right: 15px;
    }
}

/* ----------- iPad ----------- */
/* Portrait and Landscape */
@media only screen 
and (min-width: 768px) 
and (max-height: 1024px) 
and (-webkit-min-device-pixel-ratio: 1.5){
    .scrollToTop{
        margin-bottom: 15px;
        margin-right: 15px;
    }
}


/* ----------- iPad Pro ----------- */
/* Portrait and Landscape */
@media only screen 
and (min-width: 1024px) 
and (max-height: 1366px) 
and (-webkit-min-device-pixel-ratio: 1.5) {
    .dashboardSearch {
        padding: 0%;
        background-image: url("/images/Trianglify1.2.png");
        background-repeat: no-repeat;
        background-size: 100% auto;
        display: flex;
        margin-left: -100px;
        justify-content: center;
    }

    .scrollToTop{
        margin-bottom: 30px;
    }
    
    .newsSection {
        padding-top: 40px;
        /* padding-bottom: 200px; */
        border: 12px solid #eee9e9;
        border-width: 15px 0px 15px 0px;
        padding: 15px 0px 300px 0px;
    }
    .logo-area{
        padding-top: 0px;
        padding-bottom: 40px;
    }
    .greyBackground .container{
        padding-bottom: 0px;
        padding-top: 20px;
    }

}

@media (min-width: 1200px) {
    .custom .container{
        width: 1170px;
    }
    .panel-services {
        min-height: 340px;
        display: inherit;
    }
    .logo-content{
        margin-left: auto;
        margin-right: auto;
    }
    .carousel{
        margin-bottom: 15px;
    }
    .col-md-12 {
        width: 100%;
    }
    .services-text{
        color: #ffffff;
        padding-left: 9px;
        font-size: 15px;
        padding-top: 0px;
    }
}

.bg-main{
    background-color: #ffffff;
}
.card{
    margin-bottom: 15px;
}
.card-col{
    display: flex;
}
.carousel-caption h3, .carousel-caption p{
    color: #FFFFFF;
}