/* Flip Icons for RTL */
[dir=rtl] .fa-list-ul, [dir=rtl] .fa-id-card, [dir=rtl] .fa-list-alt, [dir=rtl] .fa-shopping-cart, 
[dir=rtl] .fa-caret-left, [dir=rtl] .fa-caret-right {
    transform: scaleX(-1);
}
/* Maintain LTR for Date/Time */
td[data-title*="date"], td[data-title*="Date"], td[data-title*="DATE"], td[data-title*="DEADLINE"]  {
    direction: initial;
}
label[name*="date"], label[name*="Date"], label[name*="DATE"]{
    direction: initial;
}

/* Fixes weird date/time input layout for RTl */
[dir=rtl] .rw-widget-input {
    direction: initial;
    text-align: right;
}

/* Fixes weird date/time input layout for LTR */
[dir=ltr] .rw-widget-input {
    direction: initial;
    text-align: left;
}

[dir=rtl] .result-footer .float-left {
    float: right !important;
}

[dir=rtl] .result-footer .float-right {
    float: left !important;
}

[dir=rtl] .back-btn .btn-header::before {
    content: "\f178";
}
