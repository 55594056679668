/*************** Styling: Section - SubSection ***************/
.sub-section {
    margin: 0px 0px 40px;
}

.sub-section-divider {
    margin: 30px 0px !important;    
    color: #585858 !important;
}

.sub-section-divider > span {
    font-size: 1.3rem;
}

.sub-section-divider.ant-divider-horizontal:before,
.sub-section-divider.ant-divider-horizontal:after {
    padding-top: 35px;
    border-top: .03125rem solid #585858 !important;
}

@media only screen and (max-width: 992px) {
    .sub-section-divider.ant-divider-with-text-left:before,
    .sub-section-divider.ant-divider-with-text-right:before {
        width: 50%;
    }

    .sub-section-divider.ant-divider-horizontal:before,
    .sub-section-divider.ant-divider-horizontal:after {
        width: 5%;
    }
    .sub-section-divider.ant-divider-with-text {
        overflow-wrap: break-word;
        white-space: normal;
    }
    .sub-section-divider > span {
        font-size: 1rem;
        font-weight: bolder;
        font-family: Arial, Helvetica, sans-serif;
    }
}



