
#root {
    min-width: 360px;
}
.carousel-caption {top: 0;bottom: auto;background: none;width: 100%;left: 0;right: auto;text-align: center;max-width: 100%;height:160px;}

.carousel-caption h3 {
    color: #005eb8;
}

.carousel-caption p {
    color: #333;
}

.carousel-caption h3, 
.carousel-caption p {
    text-shadow: none;
}

.home-carousel img.carousel-img {
    margin-top: 160px;
}

.carousel-caption p button.btn.btn-secondary {
    background: none;
    border: 2px solid #999;
    color: #333;
}

.carousel-indicators li{ width:36px!important; height:8px!important; margin:0 5px!important}
.home-carousel img.carousel-img, .home-carousel .carousel-item {
    width: 100%;
}

.home-carousel .carousel-item{ width:100%;}
.home-carousel{ padding:0}
.home-carousel .carousel-indicators {
    bottom: 0;
}

.login-options .panel-default > .panel-heading .panel-title {
    margin: 0;
}

.data-table .table > tbody > tr > td b {
    font-weight: 600;
}

.error-message-header {
    text-align: left;
    margin: 10px 0 5px 0;
    font-size: 1.3rem;
}
.error-message, .error-message-header {
    text-align: left;
}

.termsncond-block{ margin-left:15px; margin-right:15px;}

.checkbox-display{ left:0; top:3px; position:absolute;}
.checkbox-control:checked~.checkbox-display:after {

    font-size: 1.2rem;
    font-weight: 700;
    line-height: 100%;
}
@media only screen and (max-width:1279.88px) {
.bls-top-navbar.container {
    padding: 0 30px;
}
.container {
    width: 100% !important;
    padding: 0 15px;
    max-width: 100% !important;
}

.login-options{ margin-right:15px;}
.navbar .nav.navbar-nav {
    margin-right: 20px;
}
.nav-tabs.main-tab .nav-item {
    margin: 0;
    display: table-cell;
    width: auto;
}

.form-body.form-preview .label-default.col-lg-6.col-form-label {
    text-align: right;
}
.error-message {
    color: #5f5f5f;
    font-size: 13px;
    font-style: italic;
}
}

@media only screen and (max-width:991px) {
    .container {
        padding-left: 15px;
        padding-right: 15px;
    }	
    .navbar .nav.navbar-nav {
        margin-right: 20px;
    }
    .banner {
        background-position: top 0 center;
    }
    .guided-box .btn, .homepage-tagline .btn{ margin:3px;}
	.home-carousel img.carousel-img{ margin-top:190px;}
	

        
}
@media only screen and (max-width:767px) {
    .navbar .nav.navbar-nav{ margin-right:0;}
    .homepage-tagline h3 {
        font-size: 1.7rem;
    }

    .carousel-caption.d-none.d-md-block {
        display: block!important;
        min-width: 100%;
        position: static;
        height: 100%;
        margin-bottom: 10px;
    }

    .home-carousel img.carousel-img {
        display: none;
    }
}

@media only screen and (min-width:768px) {
    .tab-nav-tabs .tab-nav-item {
        width: auto;
        text-align: center;
    }	
    .tab-header.tab-nav-link.active {
        background:none;
    }
}
@media only screen and (max-width:991.98px) {
    .nav-tabs.main-tab .nav-item a{ font-size:1rem; margin:0 5px}
    .search-cell.search-head {
        font-size: 1em;
    }


    .form-body.form-preview {
        display: inline-block;
        width: 100%;
    }

    .form-body.form-preview  .mb-0.col-lg-6 {
        width: 50%;
    }
    .form-body.form-preview .label-default.col-lg-6.col-form-label {
        width: 50%;
    }
    .form-body.form-preview .form-group .col-lg-6 {
        width: 50%;
    }

    .result-footer .float-right {
        width: 100%;
        margin-top: 15px;
    }

    .accordion-content label.label-default {
        text-align: left;
    }
    .ant-steps-item:last-child.ant-steps-item-process .ant-steps-item-tail {
        background: none!important;
    }

    .ant-steps-item:last-child.ant-steps-item-process .ant-steps-item-tail:after {background: none;}
    .form-preview label.label-default.col-lg-4.col-form-label {
        position: relative;
        float: left;
        width: 50%;
        text-align: right;
    }
    td.card-table-td.card-table-title {
        text-align: right;
        padding-right: 10px!important;
    }
    .detailed-box.db_corr_details .form-body .form-group .col-lg-8 {
        float: left;
        width: 50%;
    }
}
@media only screen and (max-width:767.98px) {
	td.card-table-td.card-table-title {
        text-align: left;
        padding-right: 10px!important;
    }
    .tab-nav-tabs .tab-nav-item {
        width: auto;
        text-align: center;
    }	
    .inner-tabs .nav.nav-tabs .nav-item a::before {
        position: absolute;
        left: 5px;
    }
    .inner-tabs .nav.nav-tabs .nav-item a {
        padding: 5px 5px 5px 30px;
    }
    .inner-tabs .nav.nav-tabs .nav-item a span {
        margin-left: 10px;
    }
    .nav-tabs.main-tab .nav-item a {
        font-size: 0.9em;
        margin: 0 3px;
    }

    .nav-tabs.main-tab .nav-item a span {
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-size: 19px;
    }
    .tab-header.tab-nav-link.active {
        background:none;
    }
    .search-cell.search-head {
        width: 100%;
        font-size: 1.5em;
    }
    .search-box .search-cell {
        display: block;
        padding: 5px 15px;
        width: 100%;
        text-align:center;
    }
    .search-cell.search-head {
        width: 100%;
    }
    .search-box .search-cell:last-child {
        width: 100%;
    }
    .search-box .search-cell:last-child span {
        display: inline-block;
    }
    .data-table .table > tbody > tr {

        background:   #fff !important;
        display: block;
        margin: 0px 0 10px 0;
        padding-bottom: 0px;
        border-bottom: none;
        float: left;
        width: 100%;

    }
    .result-footer p {
        display: block;
        margin: 5px 0px 5px 0;
        padding-left: 35px;
        position: relative;
        min-height: 26px;
    }
    .login-options {
        width: 100%;
        padding: 0 15px;
        margin-left: 0;
        margin-right: 0;
    }
    .card .horizontal-line{ display:none;}
    .advanced-search-cell {
        display: block;
        width: 100%;
    }
    .licvence-serach .input-group .btn.btn-orange{ font-size:26px;}
    .breadcrumb-main .breadcrumb .breadcrumb-item {
        font-size: 20px !important;
    }
    .back-btn {
        float: none;
        margin: 0;
        text-align: left;
    }
    .form-body.form-preview .mb-0.row.form-group {
        float: left;
        width: 100%;
    }
    .search-licence-link.link-margin-left {
        width: 100%;
        display: block;
        margin-top: 10px;
    }
    .carousel-caption p .btn.btn-secondary {

        display: block;
        margin: 10px auto;

    }
    .search-result h4 {
        margin-top: 15px!important;
    }
    .licence-cart-div .documents-to-be-submitted h4 {
        margin-top: 0;
    }
    .termsncond-block ol {
        padding-left: 10px;
    }
    .wizard-content .card {
        padding: 15px 0;
    }
    .container .col {
        padding: 0;
    }

    .documents-cell {
        display: block;
        width: 100%!important;
    }

    .documents-blog {
        margin: 10px 0;
        display: block;
        width: 100%;
    }

    .document-uploaded p{ width:100%;}
    .review-action {
        position: static;
        margin-top: 10px;
    }
    .review-action .btn {
        margin: 0;
    }

    .data-table .table>tbody>tr>td{ text-align:left;}

    .payments .table>tbody>tr>td {
        padding-left: 50%;
        font-size: 14px;
        color: #333;
        position: relative;
        font-weight: 600;
    }
    .payments .table>tbody>tr>td::before{ font-weight:400;}
    .payments .table>tbody>tr>td:nth-child(1)::before {content: "licences";position: absolute;left: 0;top: 0;}
    .payments .table>tbody>tr>td:nth-child(2)::before {content: "fees ";position: absolute;left: 0;top: 0;}
    .payments .table>tbody>tr>td:nth-child(3)::before {content: "tax ";position: absolute;left: 0;top: 0;}
    .payments .table>tbody>tr>td:nth-child(4)::before {content: "Sub Total";position: absolute;left: 0;top: 0;}
    .payments .data-table .table tr td:last-child[colspan="4"] {
        padding-left: 0;
        font-weight: 600;
        border-bottom: 1px solid #ccc;
        font-size: 0.9rem;
        color: #666;
        margin-top: 15px;
    }
    .payments .data-table .table tfoot tr td:last-child[colspan="4"]{padding-left: .75rem;}


    .payments .data-table .table tr td:last-child[colspan="4"]::before{ display:none;}
    tfoot td {
        font-size: 1rem;
    }
    tfoot td strong {
        font-weight: 600;
        position: absolute;
        left: 50%;
        margin: 0;
    }
    .documents-cell.document-action {
        border-left: none;
        border-right: none;
    }

    .form-check-inline{margin: 0 .75rem;}
    .accordion .card{ margin-bottom:0;}
    a.form-control-plaintext.supporting-document {
        word-break: break-all;
    }
    input.griddle-filter {
        clear: both;
        margin-bottom: 30px;
    }
    .detailed-box.db_corr_details .form-body .form-group .col-lg-8 {
        float: left;
        width: 50%;
    }
    .form-preview label.label-default.col-lg-4.col-form-label {
        text-align: left;
    }
    .dashboard .table tr td, .dashboard .table tr th {
        vertical-align: top;
    }
}
@media only screen and (max-width:575.98px) {
    .nav-tabs.main-tab .nav-item {
        margin: 0 0 5px 0;
        display: block;
        width: 100%;
    }

    .inner-tabs .nav.nav-tabs .nav-item {
        margin: 0 0px 5px 0;
        width: 100%;
    }
    .inner-tabs .nav.nav-tabs .nav-item a {
        display: inline-block;
        width: 100%;
        line-height: 36px;
        padding: 5px 10px 5px 45px;
        border: 2px solid #9c9c9c;
        border-radius: 10px;
    }
    .inner-tabs .nav.nav-tabs .nav-item a span {
        margin-left: 20px;
        float: right;
    }

    .details-footer p {
        display: block;
        border: none !important;
        margin-bottom: 15px;
    }

    .form-body.form-preview .label-default.col-lg-6.col-form-label {
        text-align: left;
    }
    .button-center .btn {
        margin: 5px;
        width: auto;
        min-width: 0px;
    }
    .choose-mode .btn {
        padding: 15px 40px 15px 15px;
        margin: 4px;
    }
}


.ant-steps-item.ant-steps-item-process.wizard-step{ background:none!important; height:100px;}
.progress-steps .wizard-flex .wizard-step:before, .progress-steps .wizard-flow .wizard-step:before {
    border-radius: 50%;
    content: counter(step,decimal);
    counter-increment: step 1;
    display: block;
    font-size: 14px;
    height: 40px;
    left: 50%!important;
    line-height: 39px;
    position: absolute!important;
    text-align: center;
    width: 40px;
    background: #fff;
    top: 0!important;
    color: #999;
    font-weight: 300;
    z-index: 99999;
    margin: 0!important;
    border: 1px dotted #aaa;
	margin-left: -20px!important;

}

.progress-steps .wizard-flex .wizard-step .ant-steps-item-container, .progress-steps .wizard-flow .wizard-step .ant-steps-item-container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
}
.ant-steps:not(.ant-steps-vertical) .ant-steps-item-custom .ant-steps-item-icon {
    width: auto;
    display: none;
}
.wizard-dev-form .ant-steps-item-title {
    max-width: 120px;
    line-height: 100%;
} 
.layout-2d {
    display: flex;
    width: 100%;
    z-index: 99!important;
    position: relative!important;
} 
@media only screen and (max-width:575.98px) {
    .progress-steps .wizard-flex .wizard-step .ant-steps-item-container, .progress-steps .wizard-flow .wizard-step .ant-steps-item-container {
        opacity: 0;
        z-index: 99999;
        border: 1px solid;
        position: absolute;
    }

    .ant-steps-item.ant-steps-item-process.wizard-step {
        background: none!important;
        height: 50px;
    }

    .wizard-content .accordion-content .card-body {
        padding: 0;
    }

    .review-submit-blog .review-header h4{ font-size:1rem;}
}

.form-control-plaintext {
    word-break: break-word;
}
.card-table-body.card-body {
    white-space: nowrap;
}