.dashboardSearch {
    height: 60px;
    display: flex;
    align-items: center;
}

.dashboardSearch .smart-content {
    width: 92%;
}

.searchbar-container .col {
    flex-direction: row;
    display: flex;
}

.searchbar-container {
    width: 100%;
}

.searchbar-container > .col >.searchbar-input{
    width: 80%
}

.searchbar-input {
    align-self: center;
    margin-bottom: 0;
    padding-right: 0px;
}

.searchbar-input small {
    display: none;
}

.searchbar-input>div {
    justify-content: center;
    display: flex;
}

.searchbar-input>div>[name="title"] {
    flex-grow: 1;
}

.input-group-btn{
    width: 20%
}

.input-group-btn .btn-orange{
    padding: unset;
    width: 100%;
    color: #fff;
    border-bottom: 0;
}

@media (max-width: 992px) {
    .form-inline {}
    .dbsearchbar.form-group {
        width: 92%;
        box-sizing: content-box;
    }
    .search-button {
        height: 48px;
    }
    .dbsearchbar .form-control {
        position: relative;
        height: 40px;
        font-size: 10px;
        width: 254px
    }
    .search-navbar-item-icon {
        font-size: 14px;
    }
    /*Nav-item-icon align for Edge*/
    @supports (-ms-ime-align: auto) {
        .search-navbar-item-icon {
            font-size: 17px;
        }
    }
}

/*Nav-item-icon align for Edge*/

@supports (-ms-ime-align: auto) {
    .search-navbar-item-icon {
        font-size: 17px;
    }
}

@media only screen and (min-width: 414px) and (max-height: 736px) {
    .dbsearchbar .form-control {
        position: relative;
        height: 40px;
        font-size: 10px;
        width: 292px
    }
}