/*************** Styling: App - General ***************/
html, body {
    background-color: #FAFAFA;
    width: 100%;
    min-height: 100vh;
    margin: 0;
    padding: 0;
    scrollbar-track-color: #DDDDDD;
    scrollbar-face-color: #333333;
    font-family: 'Open Sans', sans-serif;
}

/*************** Styling: App - Scrollbar ***************/
::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-track {
    background: #DDD;
    opacity: 0.2;
}
 
::-webkit-scrollbar-thumb, ::-webkit-scrollbar-thumb:hover {
    border-radius: 40px;
    background: #333; 
}

/*************** Styling: App - Site Container ***************/
.site-container{
    /* font-family: 'Open Sans', sans-serif; */
    background-color: #FFFFFF;
    min-height: 100vh;
}

@media only screen and (max-width: 992px) {
    .tab-content > .tab-pane > .row > .container > .btn {
        width: 100%;
    }
}

/*************** Styling: App - Buttons ***************/
.btn-neutral {
    border-radius: 4px;
    background-color: #007bff;
    color: #FFFFFF;
    margin: 2px;
    border-color: none;
}

.btn-neutral:hover {
    border-color: none;
    color: #FFFFFF;
    background-color: #005dc0;
}

.btn-backward {
    border-radius: 4px;
    background-color: #5e5e5e;
    color: #FFFFFF;
    margin: 2px;
    border-color: none;
}

.btn-backward:hover {
    border-color: none;
    color: #FFFFFF;
    background-color: #353535;
}

.btn-forward {
    border-radius: 4px;
    background-color: #24b33b;
    color: #FFFFFF;
    margin: 2px;
    border-color: none;
}

.btn-forward:hover {
    border-color: none;
    color: #FFFFFF;
    background-color: #197528;
}
  
.btn-hazard {
    border-radius: 4px;
    background-color: #be2121;
    color: #FFFFFF;
    margin: 2px;
    border-color: none;
}

.btn-hazard:hover {
    border-color: none;
    color: #FFFFFF;
    background-color: #8a1717;
}

.btn-default {
    border-radius: 4px;
    margin: 2px;
}

.badge {
    color: #fff;
    margin-left: 1px;
    margin-right: 1px;
}

.badge-normal {
    background-color: #6c757d;
}

.badge-success {
    background-color: #28a745;
}

.badge-warning {
    background-color: #f79b11;
}

.badge-danger {
    background-color: #dc3545;
}
