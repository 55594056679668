/*************** Styling: Footer ***************/
.footer {
    position: fixed;
    width: 220px;
    bottom: 0;
    padding-bottom: 10px;
    padding-left: 20px;
    font-size: .7em;
}

.footer-links{
    text-transform: none;
}

.footer-bottom ul li .LicenceApp {
    display: block;
    padding: 4.9px 0;
    color: #ddd; 
    background-color: transparent;
    touch-action: manipulation;
    text-decoration: none;
    outline: none;
    cursor: pointer;
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
    -webkit-text-decoration-skip: objects;  
    width: 200px;      
}

.footer-bottom ul li .LicenceApp:hover {
    text-decoration: underline;
    color: #ddd;
}

.footer-bottom ul li .footerheader {
    display: block;
    padding: 4.5px 0;
    color: #ddd; 
    background-color: transparent;
    touch-action: manipulation;
    text-decoration: none;
    outline: none;
    -webkit-text-decoration-skip: objects;
    width: 170px;
}

/* .footer-copyright {
    padding-top: 4px;
    margin-top: 1.5px;
    margin-bottom: -15px;
    background-color: white;
} */