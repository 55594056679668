/*************** Styling: Page Footer ***************/
.page-footer{
    align-self: center;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-select-list{
    width: 50%;
    min-width: 150px;
    max-width: 300px;
}
