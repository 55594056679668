.side-navbar {
    position: fixed;
    top: 0;
    width: 280px;
    height: 100%;
    right: -280px;
    margin: 0 !important;
    background-color: #333;
    padding: 15px 0;
    z-index: 1010;
    overflow: auto;
    transition: transform 0.3s ease-out;
    font-family: "Poppins", Helvetica, Arial, sans-serif;
}

.side-navbar.nav-open {
    /* right: -280px; */
    transform: translateX(-280px);
    transition: transform 0.3s ease-out;
}
.dropdown-menu {
    text-align: center;
}

.side-navbar ul a.navbar-item.dropdownlevel-1 {
    display: block;
    width: 100%;
    text-align: left;
    padding: 10px 25px !important;
    color: #fff !important;
    text-transform: none;
    background: none;
    border-bottom: 1px solid #444;
    font-weight: 400;
    font-size: 14px;
}

.side-navbar ul li.navbar-item.dropdown-toggle {
    display: flex;
    width: 100%;
    text-align: left;
    padding: 5px 25px !important;
    color: #fff !important;
    text-transform: none;
    background: none;
    border-bottom: 1px solid #444;
    font-weight: 400;
    font-size: 14px;
    align-items: center;
    justify-content: space-between;
}

.side-navbar ul li a.dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border: none;
    content: "\f067";
    font-family: "Font Awesome 5 Pro";
    position: absolute;
    right: 15px;
    top: 10px;
    font-size: 1.5em;
}

.side-navbar ul li.active a.dropdown-toggle::after {
    content: "\f068";
}

.side-navbar ul li.navbar-item.dropdown-toggle::after {
    display: inline-flex;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border: none;
    content: "\f067";
    font-family: "Font Awesome 5 Pro";
    position: relative;
    margin-left: 0;
    font-size: 1.5em;
}

.side-navbar ul li.active.navbar-item.dropdown-toggle::after {
    content: "\f068";
}

.side-navbar .nav-collapse .navbar-item.dropdownlevel-2 {
    display: block;
    width: 100%;
    text-align: left;
    padding: 10px 25px;
    color: #fff;
    text-transform: none;
    border-bottom: 1px solid #444;
    background: #666;
}

.side-navbar .login-required {
    display: block;
    width: 100%;
    padding: 10px 25px !important;
    color: #fff !important;
    text-transform: none;
    background: none;
    border-bottom: 1px solid #444;
    font-weight: 400;
    font-size: 14px;
    background: #666;
    text-align: right;
}

.app-container.nav-open {
    /* right: 280px; */
    position: fixed;
    transform: translateX(-280px);
    transition: transform 0.3s ease-out;
}
.app-container {
    transition: transform 0.3s ease-out;
}

/** Start of Animated Menu Button Style **/

.menu-icon {
    display: inline-block;
    cursor: pointer;
}

.menu-bar1,
.menu-bar2,
.menu-bar3 {
    width: 25px;
    height: 2px;
    background-color: #d71635;
    -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -ms-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.menu-bar1 {
    margin: 0 0 6px;
}

.menu-bar2 {
    margin: 6px 0;
}

.menu-bar3 {
    margin: 6px 0 0;
}

/* Rotate first bar */
.nav-open .menu-bar1 {
    -webkit-transform: rotate(135deg) translate(6px, -6px);
    -moz-transition: rotate(135deg) translate(6px, -6px);
    -ms-transform: rotate(135deg) translate(6px, -6px);
    -o-transform: rotate(135deg) translate(6px, -6px);
    transform: rotate(135deg) translate(6px, -6px);
}

/* Fade out the second bar */
.nav-open .menu-bar2 {
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
    opacity: 0;
}

/* Rotate last bar */
.nav-open .menu-bar3 {
    -webkit-transform: rotate(225deg) translate(5px, 6px);
    -moz-transform: rotate(225deg) translate(5px, 6px);
    -ms-transform: rotate(225deg) translate(5px, 6px);
    -o-transform: rotate(225deg) translate(5px, 6px);
    transform: rotate(225deg) translate(5px, 6px);
}

/** End of Animated Menu Button Style **/

@media (min-width: 768px) {
    .mobile-navbar-container .navbar .container {
        width: 750px;
        padding: 0;
    }
}

@media (min-width: 992px) {
    .mobile-navbar-container .navbar .container {
        width: 970px;
        max-width: 970px;
    }
}

@media (min-width: 1280px) {
    .mobile-navbar-container .navbar .container {
        width: 1200px;
        max-width: 1200px;
    }
}

@media (min-width: 576px) {
    .container {
        max-width: unset;
    }
}

.mobile-navbar-container button.navbar-brand  {
    background: transparent;
    border: none;
}
