/* Minimized chatbot */

.minimizable-web-chat>button.maximize {
  background: linear-gradient( 90deg, rgba(0, 95, 184, 1) 0%, rgba(33, 171, 166, 1) 100%);
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  border: 2px solid #ffffff;
  bottom: 90px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  color: #ffffff;
  font-size: 15px;
  font-weight: 600;
  height: 55px;
  outline: 0;
  padding: 8px;
  position: fixed;
  right: -2px;
  width: 154px;
  z-index: 100;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.white-atlas-icon {
  background-image: url(img/Chatbot-White.svg);
  background-repeat: no-repeat;
  transform: scaleX(-1);
  width: 38px;
  height: 38px;
  align-self: center;
  content: "";
}

.maximize-text {
  padding-left: 10px;
}

@media screen and (max-width:768px) {
  .maximize-text {
    display: none;
    content: "";
  }
  .minimizable-web-chat>button.maximize {
    width: unset;
  }
}

.minimizable-web-chat>button.maximize>.red-dot {
  color: #EF0000;
  font-size: 14px;
  position: absolute;
  right: 10px;
  top: 8px;
  animation: shake 0.5s;
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.minimizable-web-chat>button.maximize:focus:not(:active), .minimizable-web-chat>button.maximize:hover:not(:active) {
  border-color: rgb(213, 214, 216);
}

.minimizable-web-chat>button.maximize:active {
  background-color: White;
  color: #39C;
}

.minimizable-web-chat>.chat-box {
  background-color: rgba(255, 255, 255, .8);
  backdrop-filter: blur(10px);
  border-radius: 4px;
  box-shadow: 0 0 20px rgba(0, 0, 0, .2);
  bottom: 20px;
  display: flex;
  flex-direction: column;
  max-width: 400px;
  min-width: 320px;
  position: fixed;
  top: 20px;
  width: 30%;
}

@media screen and (min-width:769px) {
  .minimizable-web-chat>.chat-box {
    max-width: 600px;
    min-width: 500px;
    height: 700px;
    max-height: 80%;
    top: unset;
  }
}

.minimizable-web-chat>.chat-box.hide {
  display: none;
}

.minimizable-web-chat>.chat-box.left {
  left: 20px;
  z-index: 100;
}

.minimizable-web-chat>.chat-box.right {
  right: 60px;
  z-index: 100;
}

.minimizable-web-chat>.chat-box>header {
  background: linear-gradient( 90deg, rgba(0, 95, 184, 1) 0%, rgba(33, 171, 166, 1) 100%);
  ;
  height: 40px;
  display: flex;
}

.minimizable-web-chat>.chat-box>header>.filler {
  flex: 0 10000 100%;
}

.minimizable-web-chat>.chat-box>header>button {
  background-color: Transparent;
  border: none;
  color: White;
  outline: 0;
}

.minimizable-web-chat>.chat-box>header>button:focus:not(:active), .minimizable-web-chat>.chat-box>header>button:hover:not(:active) {
  color: rgba(255, 255, 255, .6);
}

.minimizable-web-chat>.chat-box>header>button:focus:active {
  color: rgba(255, 255, 255, .4);
}

.minimizable-web-chat>.chat-box>.react-web-chat {
  flex: 1;
}

/* User message box color */

.chat .css-11j7slk.webchat__bubble.webchat__bubble--from-user .webchat__bubble__content {
  background: rgb(22, 173, 175);
  color: white;
}

.css-13t1vy4 {
  overflow: auto;
}

.css-1s8geyi .css-9ohtah {
  overflow: inherit;
  font-size: 12px;
}

/* Chatbox background colour */

.css-1t62idy.css-13t1vy4.chat {
  background: rgb(239,239,239);
}

/* Spaces between each card */

.webchat__stacked-layout--top-callout .webchat__stacked-layout__main {
  margin-top: 25px;
}

/* "Bot said" Message */

.webchat__stacked-layout__message-row .css-9ohtah {
  font-size: 12px;
  color: transparent;
  display: block;
  background: url(img/Chatbot-Red.svg) no-repeat;
  height: 35px;
  width: 35px;
  margin-top: 7px;
  margin-left: 5px;
  /* Remove transform to unflip icon */
  transform: scaleX(-1);
}

/* Shifting bot div boxes for icon space */

.webchat__bubble.webchat__bubble--nub-on-top.webchat__stacked-layout__message {
  margin-left: 50px;
}

/* "You said" Message */

.webchat__stacked-layout--from-user .webchat__stacked-layout__main .webchat__stacked-layout__content .webchat__stacked-layout__message-row .css-9ohtah {
  display: none;
}

/* Font and border color for popular questions */

.css-1fkc8b7.webchat__adaptive-card-renderer .ac-pushButton {
  color: rgb(50,130,184);
  border-color: transparent;
  font-weight: 400;
  border-radius: 5px;
}

/* Option box colour when hovering */

.css-1fkc8b7.webchat__adaptive-card-renderer .ac-pushButton:hover {
  color: white;
  background: rgb(0,99,167);
}

/* Option box colour after selected */

.css-1fkc8b7.webchat__adaptive-card-renderer .ac-pushButton:focus {
  color: white;
  background: rgb(0,99,167);
}

/* Chatbot messages text align */

.css-1b7yvbl {
  text-align: left;
}

/* Chatbot Rounded radius start */

.minimizable-web-chat .chat-box {
  margin-bottom: 50px;
  border-radius: 10px;
}

.minimizable-web-chat .chat-box header {
  border-radius: 10px 10px 0 0;
}

.css-1t62idy.css-13t1vy4.chat, .chat .css-o8vzus, .css-eycyw2.css-1882x3w.css-1l9a57c, .css-eycyw2.css-1882x3w.css-1l9a57c .main {
  border-radius: 0 0 10px 10px;
}

.css-1ueqw9g.webchat__icon-button, .css-1lzfo2b.css-14x775w {
  border-bottom-left-radius: 10px;
}

/* Chatbot Rounded radius end */

/* Chatbot input grey outline */

input.webchat__send-box-text-box__input.webchat__send-box-text-box__input.webchat__send-box-text-box__input {
  border-radius: 20px;
  border: 1px solid #E9E9E9;
  padding: 5px 10px;
}

.css-bgcuq0.webchat__send-box-text-box {
  padding: 7px 5px;
}

/* Microphone rounded button */

.css-10tnah1.css-124bfsw.css-mfy564 {
  background-color: #e9e9e9;
  border-radius: 20px;
  height: 40px;
  width: 40px;
  margin: 5px;
}

/* Chatbot header */

.minimizable-web-chat>.chat-box .filler {
  display: flex;
  align-self: center;
  color: #FFFFFF
}

.minimizable-web-chat>.chat-box .filler button {
  align-items: center;
}

.minimizable-web-chat .chat-box header {
  padding-left: 15px;
  padding-right: 10px;
}

/* Align header center */

.chat-bot-header-text {
  text-align: left;
}

/* Most popular questions font size */
.css-1fkc8b7.webchat__adaptive-card-renderer .ac-pushButton {
  font-size: 14px;
}

/* Most popular questions word wrap */

.ac-pushButton div {
  white-space: break-spaces !important;
}

/* Most popular questions card's background color */
.webchat__bubble.css-19kv0ae.css-11j7slk.webchat__bubble--nub-on-top.webchat__stacked-layout__attachment .webchat__bubble__content {
  background: none;
  border: none;
}

/* First message of chatbot color */
.webchat__basic-transcript__transcript .webchat__basic-transcript__activity:first-child .webchat__stacked-layout .webchat__stacked-layout__main .webchat__stacked-layout__content 
.webchat__stacked-layout__message-row .webchat__stacked-layout__message .webchat__bubble__content {
    background:rgb(0,99,167);
    color: white;
}

/* Timestamp start */

/* Timestamp position for welcome message sections */
.webchat__basic-transcript__transcript .webchat__basic-transcript__activity:nth-child(2) .webchat__stacked-layout .webchat__stacked-layout__status .activityStatus__timestamp {
  color: #767676;
  font-family: Calibri, 'Helvetica Neue', Arial, sans-serif;
  position: relative;
  top: -500px;
  left: 50px;
}

/* Timestamp adjustment message boxes */
.activityStatus {
  color: #767676;
  font-family: Calibri, 'Helvetica Neue', Arial, sans-serif;
  position: absolute;
  top: -25px;
}

/* Bot timestamp Message */

.webchat__basic-transcript__transcript .webchat__basic-transcript__activity:nth-child(2) .webchat__stacked-layout .webchat__stacked-layout__status .activityStatus__timestamp {
  color: #767676;
  font-family: Calibri, 'Helvetica Neue', Arial, sans-serif;
  position: absolute;
  top: -120px;
}

/* Timestamp adjustment for bot message boxes only */
.webchat__basic-transcript__transcript .webchat__basic-transcript__activity .webchat__stacked-layout:not(.webchat__stacked-layout--from-user) .webchat__stacked-layout__status .activityStatus__timestamp {
  left: 52px;
}

/* Font size for '{Bot name} at' */
.activityStatus__sendStatus,
.activityStatus__timestampPretext {
  font-size: 14px;
}

/* Font size for time */
.activityStatus__timestampContent {
  font-size: 14px;
}

/* Timestamp end */