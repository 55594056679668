.accordion-sub-topic {
    cursor: pointer;
}

.faq-searchbar {
    display: flex;
    justify-content: center;
}

.faq-searchbar .input-group {
    max-width: 640px;
}

.faq-searchbar .input-group .btn.btn-orange {
    padding: 0 25px;
    height: 60px;
    font-size: 40px;
    border-radius: 0 10px 10px 0;
    margin: 0;
}

.faq-searchbar .input-group .form-control {
    height: 60px;
    border: none;
    border-radius: 10px 0 0 10px;
    box-shadow: none;
}

.faq-search .faq-searchbar .input-group .form-control {
    border: 2px solid #ddd;
    border-right: none;
}

.faq-search .faq-searchbar .input-group .btn.btn-orange {
    font-size: 1rem;
}

.faq-search .faq-searchbar .input-group .btn.btn-orange i {
    font-size: 2rem;
}

.faq-search .tagline {
    margin-top: 40px;
    color: rgb(0, 94, 184);
    font-size: 32px;
    text-align: center;
}

.main-faq .browse-by-topics {
    text-align: left;
    color: rgb(0, 94, 184);
    font-size: 21px;
    padding-bottom: 10px;
}

.main-faq>.row {
    flex-flow: column;
}

div.accordion-question>div {
    text-align: left;
}

div.faq-questions.row div.col, div.faq-questions.row div.row {
    display: flex;
    align-items: center;
}

.accordion-topic {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.accordion-topic>div {
    text-transform: uppercase;
}

.accordion-topic-caret {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.key-card-body.active {
    color: rgb(255, 255, 255);
    background-color: rgb(0, 94, 184);
}

.key-card-body.active>.row {
    padding-bottom: 15px;
}

.key-card-body.closed>.row {
    padding-bottom: 0px;
}

.key-card-body.closed {
    color: rgb(102, 102, 102);
    background-color: rgb(255, 255, 255);
}

.topic-subtopic .key-card-body.card-body {
    border-radius: 5px;
}

.question-answer-display .key-card-body.card-body {
    border-radius: 5px;
    padding-bottom: 0;
}

.question-answer-display .key-card-body svg {
    font-size: 20px;
}

.question-answer-display .key-card-body.active svg {
    color: rgb(255, 255, 255);
    background-color: rgb(0, 94, 184);
}

.question-answer-display .key-c ard-body.closed svg {
    color: rgb(102, 102, 102);
    background-color: rgb(255, 255, 255);
}

.accordion-sub-topic {
    margin-top: 2px;
    margin-right: 15px;
    margin-left: 15px;
    height: 42px;
    border-radius: 5px;
}

.accordion-sub-topic.active {
    border-radius: 5px;
    color: rgb(0, 94, 184);
    background-color: rgb(255, 255, 255);
}

.accordion-sub-topic.closed {
    border-radius: 5px;
    color: rgb(0, 94, 184);
    background-color: rgb(151, 198, 255);
}

.faq-questions .topic-subtopic .card {
    margin-bottom: 5px;
}

.faq-questions .question-answer .card {
    margin-bottom: 10px;
}

.faq-questions .topic-subtopic>div {
    border-radius: 5px;
}

/* .subtopic-collapse{
    padding-top: 15px;
    transition: all 0.15s ease;
} */

.faq-questions .question-answer-display .display-question {
    background-color: #F7F7F7;
    border: 1px solid rgba(0, 0, 0, .125);
    display: flex;
    align-items: center;
    margin: -21px;
    border-radius: 5px;
    height: 44px;
    margin-bottom: 0px;
}

.display-answer {
    background-color: #FFFFFF;
}

.faq-questions .question-answer-display .display-question svg {
    margin: .375rem .75rem;
}

div.question-answer .display-answer div.col {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    text-align: left;
}

div.question-answer .display-answer div>p:nth-child(1) {
    margin-top: 10px;
}