  /*************** Open Sans **************/
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 300;
    src: local('Open Sans Light Italic'), local('OpenSans-LightItalic'), url(../../assets/fonts/OpenSans-LightItalic.ttf)  ;
  }
  @font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    src: local('Open Sans Italic'), local('OpenSans-Italic'), url(../../assets/fonts/OpenSans-Italic.ttf)  ;
  }
  @font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 600;
    src: local('Open Sans SemiBold Italic'), local('OpenSans-SemiBoldItalic'), url(../../assets/fonts/OpenSans-SemiBoldItalic.ttf)  ;
  }
  @font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 700;
    src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'), url(../../assets/fonts/OpenSans-BoldItalic.ttf)  ;
  }
  @font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 800;
    src: local('Open Sans ExtraBold Italic'), local('OpenSans-ExtraBoldItalic'), url(../../assets/fonts/OpenSans-ExtraBoldItalic.ttf)  ;
  }
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 100;
    src: local('Open Sans Light'), local('OpenSans-Light'), url(../../assets/fonts/OpenSans-Light.ttf)  ;
  }
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Open Sans Regular'), local('OpenSans-Regular'), url(../../assets/fonts/OpenSans-Regular.ttf)  ;
  }
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'), url(../../assets/fonts/OpenSans-SemiBold.ttf)  ;
  }
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: local('Open Sans Bold'), local('OpenSans-Bold'), url(../../assets/fonts/OpenSans-Bold.ttf)  ;
  }
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 800;
    src: local('Open Sans ExtraBold'), local('OpenSans-ExtraBold'), url(../../assets/fonts/OpenSans-ExtraBold.ttf)  ;
  }


  /*************** Source Sans Pro **************/

  
  @font-face {
    font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: 200;
    src: local('Source Sans Pro ExtraLight Italic'), local('SourceSansPro-ExtraLightItalic'), url(../../assets/fonts/SourceSansPro-ExtraLightItalic.ttf);
  }
  @font-face {
    font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: 300;
    src: local('Source Sans Pro Light Italic'), local('SourceSansPro-LightItalic'), url(../../assets/fonts/SourceSansPro-LightItalic.ttf);
  }
  @font-face {
    font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: 400;
    src: local('Source Sans Pro Italic'), local('SourceSansPro-Italic'), url(../../assets/fonts/SourceSansPro-Italic.ttf);
  }
  @font-face {
    font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: 600;
    src: local('Source Sans Pro SemiBold Italic'), local('SourceSansPro-SemiBoldItalic'), url(../../assets/fonts/SourceSansPro-SemiBoldItalic.ttf);
  }
  @font-face {
    font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: 700;
    src: local('Source Sans Pro Bold Italic'), local('SourceSansPro-BoldItalic'), url(../../assets/fonts/SourceSansPro-BoldItalic.ttf);
  }
  @font-face {
    font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: 900;
    src: local('Source Sans Pro Black Italic'), local('SourceSansPro-BlackItalic'), url(../../assets/fonts/SourceSansPro-BlackItalic.ttf);
  }
  @font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 200;
    src: local('Source Sans Pro ExtraLight'), local('SourceSansPro-ExtraLight'), url(../../assets/fonts/SourceSansPro-ExtraLight.ttf);
  }
  @font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 300;
    src: local('Source Sans Pro Light'), local('SourceSansPro-Light'), url(../../assets/fonts/SourceSansPro-Light.ttf);
  }
  @font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'), url(../../assets/fonts/SourceSansPro-Regular.ttf);
  }
  @font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    src: local('Source Sans Pro SemiBold'), local('SourceSansPro-SemiBold'), url(../../assets/fonts/SourceSansPro-SemiBold.ttf);
  }
  @font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'), url(../../assets/fonts/SourceSansPro-Bold.ttf);
  }
  @font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 900;
    src: local('Source Sans Pro Black'), local('SourceSansPro-Black'), url(../../assets/fonts/SourceSansPro-Black.ttf);
  }