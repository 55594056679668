/*************** Styling: FileUpload Section - Table ***************/


.docs-table {
    margin-top: 20px;
    background-color: #f5f5f6;
}

.docs-table-viewer {
    margin-top: 15px;
    background-color: #f5f5f6;
}

.docs-table-body-nofiles td {
    /* background-color: #f3f3f4 !important; */
    padding-top:10px;
    padding-bottom:10px;
}

.docs-table-body {
    background-color: #fff;
}

.docs-table-header {
    color: #6C757D;
    font-size: 0.85em;
}

.docs-table-id {
    width: 10%;
}

.docs-table-file {
    width: 35%;
    word-break: break-word;
}

.docs-table-file.child:hover {
    color: #3d6ac7;
    text-decoration: underline;
    cursor: pointer;
}

.docs-table-desc {
    width: 75%;
    word-break: break-word;
}

.docs-table-normal-col {
    width: 10%;
    word-break: break-word;
    text-align: center;
}

.docs-table-action {
    width: 20%;
    /* word-break: break-word; */
    text-align: center;
}

.docs-table-text {
    text-align: center;
}

.docs-table-text-body{
    background-color: #f3f3f4;
}

.table-fixed{
    table-layout: fixed;
    width: 100%;
}
@media (max-width: 992px) {
    .docs-table-header {
        font-size: 0.8em;
    }
    .docs-table-normal-col {
        display: none;
    }
    .docs-table-desc {
        width: 100%;
    }
}
/*************** Styling: FileUpload Section - Button ***************/


.docs-btn-delete {
    border-radius: 3px;
    background-color: #ED5565;
    border-color: #ED5565;
    color: #FFFFFF;
    max-width: 40px;
    width: 100%;
    padding: unset;
}