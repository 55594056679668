[dir=rtl] body,
html[dir=rtl] {
	direction: rtl;
}

[dir=rtl] .navbar-nav {
	padding-right: 0;
}

[dir=rtl] .navbar-nav {
	padding-right: 0;
}

[dir=rtl][dir=rtl] .sticky-right.col {
	padding-left: 0!important;
}

[dir=rtl] .fa-caret-left,
[dir=rtl] .fa-caret-right,
[dir=rtl] .fa-id-card,
[dir=rtl] .fa-list-alt,
[dir=rtl] .fa-list-ul,
[dir=rtl] .fa-shopping-cart,
[dir=rtl] .fa-caret-left,
[dir=rtl] .fa-caret-right,
[dir=rtl] .fa-id-card,
[dir=rtl] .fa-list-alt,
[dir=rtl] .fa-list-ul,
[dir=rtl] .fa-shopping-cart,
[dir=rtl] .fa-caret-left,
[dir=rtl] .fa-caret-right,
[dir=rtl] .fa-id-card,
[dir=rtl] .fa-list-alt,
[dir=rtl] .fa-list-ul,
[dir=rtl] .fa-shopping-cart,
[dir=rtl] .inner-tabs .nav.nav-tabs .nav-item a#Applications_All-tab:before,
[dir=rtl] .inner-tabs .nav.nav-tabs .nav-item a#Correspondences_All-tab:before,
[dir=rtl] .inner-tabs .nav.nav-tabs .nav-item a#Licences_All-tab:before {
	-webkit-transform: scaleX(-1);
	transform: scaleX(-1);
}

[dir=rtl] .navbar .nav.navbar-nav li.cart {
	padding-left: 0;
	padding-top: 0;
	margin-left: 30px;
}

[dir=rtl] .navbar-nav>li.cart button.empty-cart span {
	right: auto;
	left: -30px;
}

[dir=rtl] .navbar-nav>li.cart a span,
[dir=rtl] .navbar-nav>li.cart button span {
	left: -30px;
	right: auto;
}

[dir=rtl] .top-navbar {
	background: #005fb8;
	background: linear-gradient(-90deg, #005fb8, #21aba6);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#005fb8", endColorstr="#21aba6", GradientType=1);
}

[dir=rtl] .wats-new-blog li {
	text-align: right;
	padding-left: 0;
	padding-right: 20px;
}

[dir=rtl] .wats-new-blog li:before {
	left: auto;
	right: 0;
}

[dir=rtl] .footer-bottom .footer-copy a {
	margin-left: 0;
	margin-right: 40px;
}

[dir=rtl] .social-link-block {
	float: left;
}

[dir=rtl] .footer-top img {
	float: right;
}

[dir=rtl] .login-container {
	-webkit-transform: scaleX(-1);
	transform: scaleX(-1);
}

[dir=rtl] .login-options .panel-title a,
[dir=rtl] .login-options .panel-title div {
	-webkit-transform: scaleX(-1);
	transform: scaleX(-1);
	padding: 30px 70px 30px 70px;
	text-align: right;
	background-position: right 20px center!important;
}

[dir=rtl] .login-options .panel-title div.collapsed:after,
[dir=rtl] .login-options .panel-title div:after,
[dir=rtl] .login-options .panel-title a.collapsed:after,
[dir=rtl] .login-options .panel-title a:after {
	content: "\f323";
	right: auto;
	left: 20px;
}

[dir=rtl] .footer-bottom ul,
[dir=rtl] .breadcrumb-main .breadcrumb .breadcrumb-item.active,
[dir=rtl] .search-box,
[dir=rtl] td,
[dir=rtl] th,
[dir=rtl] .nav-tabs.main-tab .nav-item a,
[dir=rtl] input[type=text],
[dir=rtl] textarea,
[dir=rtl] .detailed-box .writeups,
[dir=rtl] .form-control-plaintext,
[dir=rtl] .guided-journey h4,
[dir=rtl] .refine h5,
[dir=rtl] .guided-box,
[dir=rtl] .heading-big,
[dir=rtl] .required-licence,
[dir=rtl] .heading-icon,
[dir=rtl] .questionnaire,
[dir=rtl] .required-licence,
[dir=rtl] .result-block,
[dir=rtl] .result-recommend,
[dir=rtl] .card-body .jumbotron,
[dir=rtl] .align-text-left,
[dir=rtl] .documents-to-be-submitted h4,
[dir=rtl] .recommend-licence h4,
[dir=rtl] .search-result h4,
[dir=rtl] .documents,
[dir=rtl] .navbar-nav li .dropdown-menu .dropdown-item, [dir=rtl] .termsncond-block, [dir=rtl] .heading-bg, [dir=rtl] .documents-cell, [dir=rtl] .review-submit-blog, [dir=rtl] .declaration h6, [dir=rtl] .declaration, [dir=rtl] .choose-mode, [dir=rtl] .browse-by-agency .nav.nav-tabs li.nav-item, [dir=rtl] .error-message, [dir=rtl] .error-message-header, [dir=rtl] .search-result-links a, [dir=rtl] .prereq-result-block {
	text-align: right;
}

[dir=rtl] .nav-tabs.main-tab .nav-item a span {
	margin-right: 0;
	margin-left: 10px;
}

[dir=rtl] .inner-tabs .nav.nav-tabs .nav-item {
    margin: 0 0px 0 10px;
}

[dir=rtl] .inner-tabs .nav.nav-tabs .nav-item a:before {
	left: auto;
	right: 15px;
}

[dir=rtl] .inner-tabs .nav.nav-tabs .nav-item a {
	padding: 10px 45px 13px 10px;
}

[dir=rtl] .inner-tabs .nav.nav-tabs .nav-item a span {
	margin-left: 0;
	margin-right: 20px;
}

[dir=rtl] .search-box .search-cell .btn {
	border-radius: 10px 0px 0px 10px;
}

[dir=rtl] .search-box .search-cell .form-control {
	border-radius: 0px 10px 10px 0px;
}

[dir=rtl] .inner-tabs .nav.nav-tabs {
	padding-right: 0;
}

[dir=rtl] .search-box {
	border-radius: 5px 0 5px 5px;
}

[dir=rtl] .user-profile-close {
	left: 15px;
	right: auto;
}

[dir=rtl] .clearField .fa-times {
	left: 70px;
	right: auto;
}

[dir=rtl] .datetimepicker .rw-select {
	left: 0!important;
	right: auto!important;
	text-align: right;
	border-right: 1px solid #ccc;
	border-left: 0;
}

[dir=rtl] .advanced-search-close {
	left: 0;
	right: auto;
}

[dir=rtl] .nav-tabs.main-tab .nav-item:first-child a {
	margin-right: 0;
}

[dir=rtl] .nav-tabs.main-tab .nav-item:last-child a {
	margin-left: 0;
}

[dir=rtl] .back-btn {
	float: left;
}

[dir=rtl] .back-btn .btn-header {
	padding-left: 0;
	padding-right: 40px;
}

[dir=rtl] .back-btn .btn-header:before {
	content: "\f178";
}

[dir=rtl] .back-btn .btn-header:before {
	left: auto;
	right: 0;
}

[dir=rtl] .details-footer p.date-fee {
	border-right: 1px solid #ccc;
	border-left: none;
}

[dir=rtl] .details-footer p.date-fee:first-child {
    border-right: none;
    padding-right: 0px;
    padding-left: 10px;
}

[dir=rtl] .collapse-expand {
	text-align: left;
}

[dir=rtl] .card-header {
	text-align: right;
}

[dir=rtl] .card-header .card-link {
	padding: 13px 50px 13px 13px;
}

[dir=rtl] .card-header .card-link:before {
	eft: auto;
	right: 15px;
}

[dir=rtl] .form-preview .col-form-label:after {
	left: 0;
	right: auto
}

[dir=rtl] .licence-serach .input-group .btn.btn-orange {
	border-radius: 10px 0 0 10px;
}

[dir=rtl] .licence-serach .input-group .form-control {
	border-radius: 0 10px 10px 0;
	border: 2px solid #ddd;
	border-left: none;
	border-right: 2px solid #ddd
}

[dir=rtl] .tips p,
[dir=rtl] .refine .tips h5,
[dir=rtl] .tips h5 {
	text-align: right;
}

[dir=rtl] .tips{ padding-right: 90px; padding-left: 0;}

[dir=rtl] .tips p {
	padding-right: 15px;
	padding-left: 0;
}

[dir=rtl] .tips p:before {
	left: auto;
	right: 0;
}

[dir=rtl] .guided-box {
	padding: 20px 20px 20px 80px;
	margin: 0 0 20px;
}

[dir=rtl] .guided-box:after {
	right: auto;
	left: 15px;
}

[dir=rtl] .heading-icon {
	padding-left: 0;
	padding-right: 60px;
}

[dir=rtl] .heading-icon {
	background-position: 100% 0!important;
}

[dir=rtl] .checkbox-overlay {
	right: 0;
	left: auto;
}

[dir=rtl] .bls-radio-control,
[dir=rtl] .check-control {
	padding-left: 0;
	padding-right: 34px;
	text-align: right;
}

[dir=rtl] .bls-radio-control {
	padding: 15px 50px 15px 15px;
}

[dir=rtl] .bls-overlay-radio {
	right: 15px;
	left: auto;
}

[dir=rtl] .modal-header .close {
	left: 50px;
	right: auto;
}

[dir=rtl] .result-footer .float-left {
	float: right!important;
}

[dir=rtl] .result-footer p {
	margin: 5px 0 0 20px;
}

[dir=rtl] .guided-box::after {
    content: attr(stickertitle);
    position: absolute;
    left: 15px;
    height: 70px;
    width: 70px;
    border-radius: 50%;
    background: #16adaf;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 100%;
    text-align: center;
    font-size: 0.9rem;
    font-weight: 600;
    top: 15px;
}

[dir=rtl] .card-header-modal .card-link {
	text-align: right;
	padding: 13px 50px 13px 13px;
}

[dir=rtl] .card-header-modal .card-link:before {
	left: auto;
	right: 15px;
}

[dir=rtl] .result-footer .float-right {
	float: left!important;
}

[dir=rtl] .btn.btn-add-to-cart {
	padding-right: 40px;
	padding-left: 15px;
}

[dir=rtl] .btn.btn-add-to-cart.added:before {
	left: auto;
	right: 20px;
}

[dir=rtl] .btn.btn-add-to-cart:before {
	right: 10px;
	left: auto;
}

.result-recommend {
	min-height: 210px;
}
[dir=rtl] .inner-serach .licence-serach .input-group .form-control{ border-left:none; border-right:2px solid #ddd; padding-right: 10px;}

[dir=rtl] .browse-by a, [dir=rtl] .browse-by button {
    margin-right: 0;
    margin-left: 2%;
    padding-right: 45px;
    padding-left: 10px;
}
[dir=rtl] .browse-by button:last-child {
    margin-right: 2%;
    margin-left: 0;
}

[dir=rtl] .browse-by button:before {
    right: 10px;
	left:auto;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

[dir=rtl] .browse-by button {
    text-align: right;
}


[dir=rtl] .progress-steps .wizard-flex .wizard-step:first-child:after, [dir=rtl] .progress-steps .wizard-flow .wizard-step:first-child:after {
    left: auto;
    right: 50%;
}

[dir=rtl] .review-action {
    right: auto;
    left: 15px;
}
[dir=rtl] .checkbox-display {
    left: auto;
    right: 0;
}

[dir=rtl] .form-check-label {
    padding-left: 0;
    text-align: right;
    padding-right: 20px;
}
[dir=rtl] tfoot td strong {
    float: left;
    margin-right: 50px;
    margin-left: 40px;
}


[dir=rtl] .radio-display {
    right: 0;
    left: auto;
}

[dir=rtl] .licvence-serach .input-group .btn.btn-orange {
    border-radius: 10px 0 0 10px;
}

[dir=rtl] .licvence-serach .input-group .form-control {
    border-radius: 0 10px 10px 0;
}

[dir=rtl] .browse-by-agency .nav.nav-tabs {
    padding: 0;
}


[dir=rtl] .browse-by-agency .nav.nav-tabs li.nav-item .nav-link {
    padding-right: 1rem;
    padding-left: 55px;
}
[dir=rtl] .browse-by-agency .nav.nav-tabs li.nav-item .nav-link .sup {
    left: 15px;
    right: auto;
}
[dir=rtl] .div-pre-req-badge {
    float: right;
}
[dir=rtl] .div-pre-req {
    text-align: right;
    padding: 20px 0;
}

[dir=rtl]  .float-right {
    float: left!important;
}

[dir=rtl] .float-left {
    float: right!important;
}
[dir=rtl] .prereq-first-licence-result-footer p {
    margin: 5px 0px 0 20px;
	
}
[dir=rtl] .progress-steps .wizard-flex .wizard-step:last-child:after, [dir=rtl] .progress-steps .wizard-flow .wizard-step:last-child:after {
    left: 50%;
    right: auto;
}
[dir=rtl] .search-result h3 {
    text-align: right;
}

.recommend p strong {
    color: #d71635;
    font-weight: 600;
    font-size: 1.7em;
    line-height: 1.2;
}

[dir=rtl] tfoot td {
    text-align: left;
}
