@font-face {
    font-family: 'Poppins';
    src: url('../../assets/fonts/Poppins-Light.woff2') format('woff2'),
        url('../../assets/fonts/Poppins-Light.woff') format('woff'),
        url('../../assets/fonts/Poppins-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('../../assets/fonts/Poppins-Regular.woff2') format('woff2'),
        url('../../assets/fonts/Poppins-Regular.woff') format('woff'),
        url('../../assets/fonts/Poppins-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('../../assets/fonts/Poppins-Bold.woff2') format('woff2'),
        url('../../assets/fonts/Poppins-Bold.woff') format('woff'),
        url('../../assets/fonts/Poppins-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('../../assets/fonts/Poppins-SemiBold.woff2') format('woff2'),
        url('../../assets/fonts/Poppins-SemiBold.woff') format('woff'),
        url('../../assets/fonts/Poppins-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

