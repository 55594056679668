/*************** Styling: Error Page ***************/


.error-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 1);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
}

.error-header {
    font-weight: 500;
    font-size: 25px;
    color: #525252;
    margin-bottom: 0px;
}

.error-content {
    text-align: center;
    color: #303030;
    text-align: center;
    padding: 5px 30px;
}

.error-icon {
    width: 120px;
    height: 120px;
    color: #c55252;
    font-size: 5em;
}

.error-id-message {
    color: #7a7a7a;
    font-size: 13px;
}

.error-btn-group {
    margin-top: 30px;
    margin-bottom: 150px;
}

.reload-icon {
    width: 120px;
    height: 120px;
    color: #CCC;
    font-size: 5em;
}

.error-btn-back {
    width: 120px;
    margin: 0px 10px 0px;
    background-color: #747E86 !important;
    border-color: #A7A7A7 !important;
}

.error-btn-back:active {
    background-color: #004777 !important;

}

.error-btn-reload {
    width: 120px;
    margin: 0px 10px 0px;
}