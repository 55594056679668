/*************** Styling: Content - General ***************/
@media (max-width: 992px){
   
} 

/*************** Styling for Mobile View ***************/
@media only screen and (max-width: 992px) {
    
    .body-content {
        padding: 0;
        margin: 0;
    }

    .body-content .body-section-col{
        padding: 0;
        margin: 0;
    }
}

/*************** Styling: Workflow ***************/

.modal-remarks .modal-body .row {
    flex-direction: column;
}


[dir=ltr] .modal-remarks .modal-body .label-default{
    text-align: left;
    font-size: 0.85rem;
}
[dir=rtl] .modal-remarks .modal-body .label-default{
    text-align: right;
    font-size: 0.85rem;
}

[dir=ltr] .modal-remarks .modal-body .label-error{
    text-align: left;
    font-size: 0.85rem;
}
[dir=rt] .modal-remarks .modal-body .label-error{
    text-align: right;
    font-size: 0.85rem;
}

.modal-remarks .modal-body textarea{
    display: block;
    width: 100% !important;
    height: 100px;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.modal-remarks .griddle-filter{
    display: none;
}

@media (min-width: 992px){
    .modal-dialog.modal-history {
        max-width: 900px;
    }
}

[dir=ltr] .licence-list {
    list-style-position: inside;
    text-align: left;
}
[dir=rtl] .licence-list {
    list-style-position: inside;
    text-align: right;
}

.pre-req-display {
    border-top: 1px solid #bbb;
    padding: 0.75rem;
}

[dir=ltr] .div-modal-body {
  text-align: left;
}
[dir=rtl] .div-modal-body {
    text-align: right;
}

[dir=ltr] .div-modal-body .label-error {
  text-align: left;
}
[dir=rtl] .div-modal-body .label-error {
    text-align: right;
}

[dir=ltr] .btn.btn-exclusive-add-to-cart::before {
    font-size: 22px;
    font-weight: bold;
    margin-right: 5px;
    position: absolute;
    top: 45%;
    margin-top: -11px;
    height: 22px;
    font-family: "Font Awesome 5 Pro";
    line-height: 100%;
    color: #ffae00;
    left: 83%;
    content: "\f055";
}
[dir=rtl] .btn.btn-exclusive-add-to-cart::before {
    font-size: 22px;
    font-weight: bold;
    margin-left: 5px;
    position: absolute;
    top: 45%;
    margin-top: -11px;
    height: 22px;
    font-family: "Font Awesome 5 Pro";
    line-height: 100%;
    color: #ffae00;
    right: 83%;
    content: "\f055";
}

.btn.btn-exclusive-add-to-cart.added {
    background: #ffae00;
    color: #fff;
}

[dir=ltr] .btn.btn-exclusive-add-to-cart {
    border-color: #ffae00;
    padding-left: 40px;
    min-width: 136px;
}
[dir=rtl] .btn.btn-exclusive-add-to-cart {
    border-color: #ffae00;
    padding-right: 40px;
    min-width: 136px;
}

[dir=ltr] .btn.btn-exclusive-add-to-cart.added::before {
    content: "\f057";
    left: 84%;
    color: #fff;
}
[dir=rtl] .btn.btn-exclusive-add-to-cart.added::before {
    content: "\f057";
    right: 84%;
    color: #fff;
}

[dir=ltr] .exclusive-result-block {
    background: #fff;
    padding: 20px;
    position: relative;
    text-align: left;
    padding-bottom: 68px;
}
[dir=rtl] .exclusive-result-block {
    background: #fff;
    padding: 20px;
    position: relative;
    text-align: right;
    padding-bottom: 68px;
}

.div-exclusive-licence-set{
    border: 1px solid #ccc;
    border-radius: 4px;
}

.div-exclusive-licence-divider{
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #BD4932;
    line-height: 0.1em;
    margin: 20px 0 20px;
}

.div-exclusive-licence-centre-box{
    background: #BD4932;
    padding: 8px;
    font: #fff;
    color: #fff;
    border-radius: 4px;
    border: 1px solid #BD4932;
}

[dir=ltr] .div-exclusive-licence-set h5{
    color: #005eb8;
    text-align: left;
    padding: 20px;
}
[dir=rtl] .div-exclusive-licence-set h5{
    color: #005eb8;
    text-align: right;
    padding: 20px;
}


[dir=ltr] .pre-req-licence-container p.pre-req-display {
    background: #1570ad;
    color: #fff;
    border-radius: 4px 4px 0 0;
    margin-bottom: 0 !Important;
    padding-left: 20px
}
[dir=rtl] .pre-req-licence-container p.pre-req-display {
    background: #1570ad;
    color: #fff;
    border-radius: 4px 4px 0 0;
    margin-bottom: 0 !Important;
    padding-right: 20px
}

[dir=ltr] .pre-req-licence-container ul.licence-list {
    list-style: none;
    padding-left: 20px;
    border: 1px solid #ddd;
    border-top: none;
    border-radius: 0 0 4px 4px
}
[dir=rtl] .pre-req-licence-container ul.licence-list {
    list-style: none;
    padding-right: 20px;
    border: 1px solid #ddd;
    border-top: none;
    border-radius: 0 0 4px 4px
}

[dir=ltr] .pre-req-licence-container ul.licence-list li {
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
    margin-right: 20px;
}
[dir=rtl] .pre-req-licence-container ul.licence-list li {
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
    margin-left: 20px;
}

ul.licence-list li:last-child {
    border-bottom: none;
}

.pre-req-display {
    border-top: none;
}

.modal-footer {
    padding-bottom: 40px;
    padding-top: 0px;
}

ul.licence-list:empty {
    display: none;
}

[dir=ltr] .modal-header.hasIcon h5:before {
    content: "\f31a";
    font-family: "Font Awesome 5 Pro";
    font-size: 1.3em;
    font-weight: 600;
    padding-right: 8px;
}
[dir=rtl] .modal-header.hasIcon h5:after {
    content: "\f31a";
    font-family: "Font Awesome 5 Pro";
    font-size: 1.3em;
    font-weight: 600;
    padding-left: 8px;
}

[dir=ltr] .prereq-first-licence-result-footer {
    position: absolute;
    left: 0;
    width: 100%;
    background: #f7f7f7;
    border-radius: 0 0 6px 6px;
    padding: 8px 20px;
}
[dir=rtl] .prereq-first-licence-result-footer {
    position: absolute;
    right: 0;
    width: 100%;
    background: #f7f7f7;
    border-radius: 0 0 6px 6px;
    padding: 8px 20px;
}

[dir=ltr] .prereq-first-licence-result-footer .btn {
    border-width: 2px;
    border-style: solid;
    border-color: #9c9c9c;
    padding: 3px 15px;
    vertical-align: middle;
    position: relative;
    margin-left: 4px;
    color: #666;
    background: #fff;
    font-size: 14px;
}
[dir=rtl] .prereq-first-licence-result-footer .btn {
    border-width: 2px;
    border-style: solid;
    border-color: #9c9c9c;
    padding: 3px 15px;
    vertical-align: middle;
    position: relative;
    margin-right: 4px;
    color: #666;
    background: #fff;
    font-size: 14px;
}

[dir=ltr] .prereq-first-licence-result-footer p {
    display: inline-block;
    vertical-align: middle;
    margin: 5px 20px 0 0;
    padding-left: 35px;
    position: relative;
}
[dir=rtl] .prereq-first-licence-result-footer p {
    display: inline-block;
    vertical-align: middle;
    margin: 5px 0 0 20px;
    padding-right: 35px;
    position: relative;
}

.prereq-first-licence-result-footer p strong {
    font-weight: 600;
}

[dir=ltr] .prereq-first-licence-result-footer p::after {
    position: absolute;
    left: 0;
    font-size: 30px;
    line-height: 100%;
    height: 30px;
    width: 30px;
    top: 50%;
    margin-top: -15px;
    font-weight: 300;
    color: #999;
}
[dir=rtl] .prereq-first-licence-result-footer p::after {
    position: absolute;
    right: 0;
    font-size: 30px;
    line-height: 100%;
    height: 30px;
    width: 30px;
    top: 50%;
    margin-top: -15px;
    font-weight: 300;
    color: #999;
}

.prereq-first-licence-result-footer p.fee::after {
    content: "\f2e8";
    font-family: "Font Awesome 5 Pro";
}

.prereq-first-licence-result-footer p.time::after {
    content: "\f017";
    font-family: "Font Awesome 5 Pro";
}

[dir=ltr] .prereq-result-block {
    border: 1px solid #ccc;
    background: #fff;
    padding: 20px 20px 0px 20px;
    border-radius: 5px;
    position: relative;
    text-align: left;
    padding-bottom: 46px;
    font-size: 14px;
    margin-bottom: 20px;
}
[dir=rtl] .prereq-result-block {
    border: 1px solid #ccc;
    background: #fff;
    padding: 20px 20px 0px 20px;
    border-radius: 5px;
    position: relative;
    text-align: right;
    padding-bottom: 46px;
    font-size: 14px;
    margin-bottom: 20px;
}

.prereq-result-block p {
    color: #666;
}

.prereq-following-licence-result {
    bottom: 0px; 
    padding-top: 70px;
}

[dir=ltr] .div-pre-req-badge {
    background: #BD4932;
    width: fit-content;
    padding: 5px 20px 5px 20px;
    color: #fff;
    border: 1px solid;
    border-radius: 4px;
    float: left;
    font-weight: bold;
}
[dir=rtl] .div-pre-req-badge {
    background: #BD4932;
    width: fit-content;
    padding: 5px 20px 5px 20px;
    color: #fff;
    border: 1px solid;
    border-radius: 4px;
    float: right;
    font-weight: bold;
}

.div-pre-req {
    padding: 20px 0px 20px 0px;
}

.pre-req-result-details h5 {
    color: #BD4932;
}

.prereq-following-licence-result h5 {
    color: #005eb8;
}

.div-pre-req-desc {
    padding: 10px;
    font-weight: bold;
}

.div-pre-req-show-hide {
    cursor: pointer;
    color: #BD4932;
    font-weight: bold;
}

[dir=ltr] .div-pre-req-show::after{
    cursor: pointer;
    color: #BD4932;
    font-weight: bold;
    content: "\f0ab";
    font-family: "Font Awesome 5 Pro";
    padding-left: 10px;
}
[dir=rtl] .div-pre-req-show::after{
    cursor: pointer;
    color: #BD4932;
    font-weight: bold;
    content: "\f0ab";
    font-family: "Font Awesome 5 Pro";
    padding-right: 10px;
}

[dir=ltr] .div-pre-req-hide::after{
    cursor: pointer;
    color: #BD4932;
    font-weight: bold;
    content: "\f0aa";
    font-family: "Font Awesome 5 Pro";
    padding-left: 10px;
}
[dir=rtl] .div-pre-req-hide::after{
    cursor: pointer;
    color: #BD4932;
    font-weight: bold;
    content: "\f0aa";
    font-family: "Font Awesome 5 Pro";
    padding-right: 10px;
}