/** CrimsonLogic UX Practice R2-S2 Foundation **/
/** Project Specific CSS **/


/** Main Containers Styling **/
html {
    font-size: 16px;
}
html[dir=ltr], body {
    height: 100%;
    margin: 0;
    padding: 0;
}
body {
    position: relative;
    margin: 0;
    padding: 0;
    right: 0;
    font-family: "Poppins", Helvetica, Arial, sans-serif;
    font-size: 1rem;
    font-weight: normal;
    text-align: center;
    color: #333;
    background: #efefef;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.site-container {
    font-size: 16px;
}

.site-container {
    height: 100%;
    margin: 0;
    padding: 0;
}

.site-container {
    position: relative;
    margin: 0;
    padding: 0;
    right: 0;
    font-family: "Poppins", Helvetica, Arial, sans-serif;
    font-size: 1rem;
    font-weight: normal;
    text-align: center;
    color: #333;
    background: #ffffff;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.container-fluid {
    overflow: hidden;
}

* {
    outline: none !important;
}
.wrapper {
    min-width: 320px;
    outline: none;
    position: relative;
    min-height: 100%;
}

@media (min-width: 1280px) {
    .container {
        width: 1200px;
    }
}

@media (min-width: 1280px) {
    .site-container .container {
        width: 1200px;
        max-width: 1200px;
    }
}

/** Navigation Styling **/
/** If there is no Navigation, please delete the whole block below */

.top-navbar {
    background: rgb(0, 95, 184);
    background: -moz-linear-gradient( 90deg, rgba(0, 95, 184, 1) 0%, rgba(33, 171, 166, 1) 100%);
    background: -webkit-linear-gradient( 90deg, rgba(0, 95, 184, 1) 0%, rgba(33, 171, 166, 1) 100%);
    background: linear-gradient( 90deg, rgba(0, 95, 184, 1) 0%, rgba(33, 171, 166, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#005fb8", endColorstr="#21aba6", GradientType=1);
}


.top-navbar {
    font-family: "Poppins", Helvetica, Arial, sans-serif;
    font-size: 1rem;
    font-weight: normal;
}

/** BLS NAVBAR START **/

.bls-top-navbar button, button.navbar-brand {
    background: transparent;
    border: none;
    color: #fff;
    font-size: 0.85em;
}

.bls-top-navbar .navbar-nav>li>a, .bls-top-navbar .navbar-nav>li>button {
    padding: 0;
    /* font-size: 0.85em; */
    color: #fff;
    /* background: none !important; */
}

.bls-top-navbar .navbar-left>li>a, .bls-top-navbar .navbar-left>li>button {
    padding: 0;
    font-size: 0.85em;
    color: #fff;
    /* background: none !important; */
    padding-left: 15px;
    padding-right: 15px;
}

.bls-top-navbar .navbar-left>li:first-child button {
    padding-left: 0;
}

.bls-top-navbar .navbar-nav {
    flex-direction: row;
    align-self: stretch;
    align-items: center;
}

.bls-top-navbar .navbar-nav>li, .bls-top-navbar .navbar-nav>li>a, .bls-top-navbar .navbar-nav>li>button {
    height: 100%;
    align-items: center;
    display: flex;
}

.bls-top-navbar .navbar {
    padding: 0;
}

.bls-top-navbar .navbar-collapse {
    display: flex;
    justify-content: space-between;
}

/* .bls-top-navbar .navbar-left {
  margin-top: 7px;
} */

.bls-btm-navbar .navbar-nav>li>a {
    color: #333;
}

.bls-btm-navbar .navbar>.container {
    justify-content: initial;
}

.bls-btm-navbar .navbar-nav .nav-link {
    padding-left: 15px;
    padding-right: 15px;
    background: transparent;
}

.bls-btm-navbar .navbar-nav {
    flex-direction: row;
}

.top-navbar .nav>li>a:hover {
    background-color: transparent;
}

.navbar-collapse:after, .navbar-collapse:before, .bls-btm-navbar .container:before, .bls-btm-navbar .container:after, .bls-top-navbar.container:before, .bls-top-navbar.container:after {
    display: flex;
    content: none;
}

.navbar-collapse:after, .navbar-collapse:before {
    display: flex;
    content: none;
}

@media (min-width: 768px) {
    .bls-top-navbar.container, .bls-btm-navbar .container {
        width: 750px;
    }
    .banner .container, .find-apply .container, .whats-new .container, .guided-simplified .container, .faq .container, .footer-bottom .container {
        max-width: 750px;
    }
    .breadcrumb-main .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .bls-top-navbar.container, .bls-btm-navbar .container {
        width: 970px;
        padding: 0 10px;
    }
    .banner .container, .find-apply .container, .whats-new .container, .guided-simplified .container, .faq .container, .footer-bottom .container {
        max-width: 970px;
    }
    .breadcrumb-main .container {
        max-width: 960px;
    }
}

@media (min-width: 1280px) {
    .bls-top-navbar.container, .bls-btm-navbar .container, .banner .container, .find-apply .container, .whats-new .container, .guided-simplified .container, .faq .container, .footer-bottom .container, .breadcrumb-main .container {
        width: 1200px;
        max-width: 1200px;
    }
}

/** BLS NAVBAR END **/

.top-navbar .navbar-nav>li .navbar-item {
    padding: 1px 10px;
    font-size: 0.85em;
    color: #fff;
    /* background: none !important; */
}

.top-navbar .navbar-nav>li .navbar-item i {
    margin-right: -5px;
}

/* .top-navbar .navbar-nav > li .notices .navbar-item i {
    color: transparent;
    stroke: white;
    stroke-width: 60px;
} */

.top-navbar .navbar-nav>li .navbar-item:hover, .top-navbar .navbar-nav>li>button .navbar-item:hover {
    color: #ddd;
}

.top-navbar .navbar-nav>li:first-child a .navbar-item {
    padding-left: 0;
}

.top-navbar .navbar-nav>li:last-child a .navbar-item {
    padding-right: 0;
    padding-left: 0;
}

.bls-top-navbar .navbar-nav>li i {
    margin-right: -5px;
}

.bls-top-navbar .navbar-nav>li:hover, .bls-top-navbar .navbar-nav>li>button:hover {
    color: #ddd;
}

.top-navbar .navbar-nav>li:first-child .navbar-item {
    padding-left: 0;
}

.top-navbar .navbar-nav>li:last-child .navbar-item {
    padding-right: 0;
}

.navbar-container {
	position: relative;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    z-index: 99;
}
.navbar {
    position: relative;
    width: 100%;
    min-height: 50px;
    border-radius: 0;
    border: 0;
    margin: 0 auto;
    background: #ffffff;
    border-bottom: 1px solid #ddd;
}

.bls-btm-navbar.navbar {
    padding-top: 0px;
    padding-bottom: 0px;
}

.navbar-nav>li.cart button.empty-cart span {
    position: absolute;
    width: 50px;
    top: 5px;
    right: -20px;
    background: #dbdbdb;
    height: 50px;
    border-radius: 50%;
    border: 2px solid #fff;
    line-height: 46px;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
}

.navbar-brand {
    height: auto;
    padding: 15px 0px;
}

.navbar a .navbar-item:hover {
    color: #000;
}

.navbar-header {
    padding-left: 15px !important;
}

.navbar-container.boxed .navbar-header {
    padding-left: 0 !important;
}

.navbar-right {
    padding-right: 15px !important;
}

.navbar-toggle {
    border: 0;
    color: #fff;
    border-radius: 0;
    margin: 22px 10px 5px 0;
    padding: 5px 5px 5px;
    background: none;
}

.navbar-container.boxed .navbar-toggle {
    margin-right: 7px;
}

.navbar-brand:hover {
    color: #fff !important;
}

.navbar-collapse {
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    padding: 0;
}

.login .btn-orange {
    width: auto;
    padding: 13px 25px;
    border: none;
}

.login .btn-orange i {
    display: none;
}

.loggedin i {
	font-size: 40px;
    color: #005eb8;
    cursor: pointer;
}
.loggedin i {
	margin: 0 10px;
	position: relative;
}
.loggedin i {
	font-size: 40px;
	color: #005eb8;
}

.loggedin span.hide {
	content: "";
	position: absolute;
	border-top: 10px solid #005eb8;
	border-left: 10px solid transparent;
	top: 100%;
	border-right: 11px solid transparent;
	left: 50%;
	margin-left: -10px;
	margin-top: 2px;
}
.loggedin span.show {
    content: "";
	position: absolute;
	border-bottom: 10px solid #005eb8;
	border-left: 10px solid transparent;
	top: 100%;
	border-right: 11px solid transparent;
	left: 50%;
	margin-left: -10px;
	margin-top: 2px;
}

.navbar-nav>li.cart a, .navbar-nav>li.cart button {
    font-size: 36px;
    padding: 10px 20px 10px 10px;
    position: relative;
    line-height: 100%;
    color: #005eb8;
}

.navbar-nav>li.cart button.empty-cart {
    position: relative;
    line-height: 100%;
    color: #dbdbdb;
}

.navbar-nav>li>a .navbar-item {
    color: #333;
}

.navbar-nav>li>a .navbar-item:hover {
    background: none;
    color: #000;
}

.nav .open>a, .nav .open>a:focus, .nav .open>a:hover {
    background: none;
    border: none;
}

.navbar-nav>li.cart button {
    background-color: transparent;
    border: none;
}

.navbar-nav>li.cart a:hover, .navbar-nav>li.cart button:hover {
    color: #f55200;
}

.navbar-nav>li.cart button.empty-cart:hover {
    color: #dbdbdb;
}

.navbar .nav.navbar-nav {
    margin-top: 10px;
    align-items: center;
}

.navbar .nav.navbar-nav li {
    padding-left: 15px;
}

.navbar .nav.navbar-nav li.cart {
    padding-left: 0px;
    padding-top: 0px;
}

.navbar-nav>li>a .navbar-item:focus, .navbar-nav>li>a .navbar-item:hover {
    color: #333;
    background: none;
}

.navbar-nav>li.cart a span, .navbar-nav>li.cart button span {
    position: absolute;
    width: 50px;
    top: 5px;
    right: -20px;
    background: #d71635;
    height: 50px;
    border-radius: 50%;
    border: 2px solid #fff;
    line-height: 46px;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
}

.navbar-nav>li.cart a:hover span, .navbar-nav>li.cart button:hover span {
    background: rgb(0, 95, 184);
    background: -moz-linear-gradient( 90deg, rgba(245, 82, 0, 1) 0%, rgba(245, 207, 0, 1) 100%);
    background: -webkit-linear-gradient( 90deg, rgba(245, 82, 0, 1) 0%, rgba(245, 207, 0, 1) 100%);
    background: linear-gradient( 90deg, rgba(245, 82, 0, 1) 0%, rgba(245, 207, 0, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#f55200", endColorstr="#f5cf00", GradientType=1);
}

.navbar-nav>li.cart button.empty-cart:hover span {
    background: #dbdbdb;
}

.top-navbar .navbar-right a .navbar-item .fal {
    font-size: 20px;
    margin-right: 5px;
    vertical-align: middle;
}

.navbar-nav li .dropdown-menu {
    background: #333;
    border: none;
    padding: 0;
    border-radius: 5px;
    min-width: 200px;
}

.navbar-nav li .dropdown-menu .dropdown-item {
    color: #fff;
    background: none;
    padding: 10px 10px;
    border-bottom: 1px solid #555;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    white-space: nowrap;
    font-size: 14px;
    text-align: left;
}

.navbar-nav li .dropdown-menu .dropdown-item:last-child {
    border: none;
}
.user-profile-overlay {
	z-index: -1;
	position: fixed;
	top: 0;
	left: 0;
	background: rgba(255,255,255,0.80);
	width: 100%;
	height: 100%;
	display: block;
}
.body-fixed {
	overflow: hidden;
}
.user-profile {
	width: 100%;
	position: fixed;
	height: 170px;
	left: 0;
	background: rgb(33,171,166);
	background: -moz-linear-gradient(90deg, rgba(33,171,166,1) 0%, rgba(0,95,184,1) 100%);
	background: -webkit-linear-gradient(90deg, rgba(33,171,166,1) 0%, rgba(0,95,184,1) 100%);
	background: linear-gradient(90deg, rgba(33,171,166,1) 0%, rgba(0,95,184,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#21aba6", endColorstr="#005fb8", GradientType=1);
	top: 98px;
	color: #fff;
	padding: 40px 0;
	font-weight: 300;
	display: block;
}
.is-sticky .user-profile {
	top: 70px;
}
.user-profile .user-name i {
	vertical-align: bottom;
	margin-left: 10px;
	font-size: 30px;
	font-weight: 300;
}
.user-profile .user-name {
	font-weight: 300;
    font-style: italic;
    color: #fff;
    width: 100%
}

.user-profile .loggedin-time {
	font-weight: 300;
	font-size: 12px;
	color: #ddd;
}
.user-profile .container {
    position: relative;
}
.user-profile-close {
	position: absolute;
	top: 0;
	right: 15px;
	font-size: 30px;
	line-height: 100%;
	color: #ddd;
}
.user-profile-close:hover {
	cursor: pointer;
}
.representing p strong {
	display: block;
	font-weight: 300;
	font-size: 17px;
	color: #fff;
}
.representing p {
	color: #ddd;
	font-size: 13px;
}
.representing {
	margin-top: 40px;
}

.profile-options {
    width: 100%;
}

.profile-options ul {
	padding: 0;
	list-style: none;
	text-align: center;
}
.profile-options ul li {
	display: inline-block;
	margin: 0 5px;
}
.profile-options ul li span {
	display: block;
	font-size: 14px;
	color: #fff;
	padding: 5px 15px;
	border: 2px solid #69acd1;
	border-radius: 5px;
	min-width: 150px;
    text-decoration: none!important;
    cursor: pointer;
}
.profile-options ul li span:hover {
	border-color: #fff;
}

/***** MOBILE NAVBAR *****/

.mobile-navbar-container {
    width: 100%;
    display: flex;
    font-family: "Poppins", Helvetica, Arial, sans-serif;
}

.mobile-navbar-container .navbar {
    height: 70px;
    padding: 3px 0;
    display: flex;
    justify-content: space-between;
}

.mobile-navbar-container .navbar .container {
    display: flex;
    justify-content: space-between;
}

.mobile-navbar-container .navbar .container>* {
    display: flex;
    align-items: center;
}

.mobile-navbar-container .container:after,.mobile-navbar-container .container:before {
    display: flex;
    content: none;
}

.mobile-navbar-container .navbar-brand {
    padding: 0;
}

.mobile-navbar-container .nav.navbar-nav {
    flex-direction: row;
}

.mobile-navbar-container .navbar-toggle {
    margin: 0;
    padding-right: 0;
}

.mobile-navbar-container .login a {
    margin: 0 9px;
}

.find-apply {
    margin-right: -15px;
    margin-left: -15px;
}

.whats-new {
    margin-right: -15px;
    margin-left: -15px;
}

.guided-simplified {
    margin-right: -15px;
    margin-left: -15px;
}

.faq {
    margin-right: -15px;
    margin-left: -15px;
}

.separator {
	border-top: 1px dashed #ccc;
	margin: 40px 0;
}

@media only screen and (max-width: 340px) {
    .licvence-serach .input-group .btn.btn-orange {
        padding: 0 10px !important;
    }
}

@media only screen and (max-width: 767px) {
    body.nav-open {
        right: 280px;
        overflow: hidden;
    }
    .wrapper.sticky .navbar-container.nav-open {
        left: -280px;
    }
    .navbar-right {
        padding-right: 0 !important;
    }
    .navbar-form {
        margin: 0;
    }
    .navbar-collapse {
        position: fixed;
        top: 0;
        width: 280px;
        height: 100%;
        right: -280px;
        margin: 0 !important;
        background-color: #333;
        padding: 15px 0;
        z-index: -10;
        overflow: auto;
    }
    .navbar-collapse.nav-open {
        right: 0;
    }
    .dropdown-menu {
        text-align: center;
    }
    .licvence-serach .input-group .btn.btn-orange {
        padding: 0 15px;
        height: 60px;
        font-size: 40px;
        border-radius: 0 10px 10px 0;
        margin: 0;
    }
}

@media only screen and (min-width: 1280px) {
    .find-apply-col-1 {
        width: 58.33333% !important;
        max-width: 58.33333% !important;
        flex: none !important;
    }
    .find-apply-col-2 {
        width: 41.66666667% !important;
        max-width: 41.66666667% !important;
    }
}

/** Delete till here if there is no Navigation **/
.main-content {
    position: relative;
    /* margin: 0 auto; */
    padding-bottom: 270px;
}
.banner {
	background: url(../../assets/images/Banner6a.jpg) no-repeat center center;
	min-height: 437px;
	color: #fff;
	position: relative;
}

.banner-background {
	background: url(../../assets/images/bg-repeat.jpg);
	background-repeat: repeat-x;
	content: "";
	width: 100%;
	height: 100%;
	z-index: -1;
}

.homepage-tagline {
    background: rgb(239, 239, 239);
    background: -moz-linear-gradient(0deg, rgba(239, 239, 239, 1) 0%, rgba(255, 255, 255, 1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(239, 239, 239, 1) 0%, rgba(255, 255, 255, 1) 100%);
    background: linear-gradient(0deg, rgba(239, 239, 239, 1) 0%, rgba(255, 255, 255, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#efefef", endColorstr="#ffffff", GradientType=1);
    padding: 20px 0;
}

.homepage-tagline h3 {
    color: #005eb8;
    font-size: 2rem;
}

.homepage-tagline h4 {
    color: #666;
}

.homepage-tagline h3 b {
    color: #e66f00;
}
.btn-orange {
	background: rgb(245,82,0);
	background: -moz-linear-gradient(0deg, rgba(245,82,0,1) 0%, rgba(245,207,0,1) 100%);
	background: -webkit-linear-gradient(0deg, rgba(245,82,0,1) 0%, rgba(245,207,0,1) 100%);
	background: linear-gradient(0deg, rgba(245,82,0,1) 0%, rgba(245,207,0,1) 100%);
 filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f55200", endColorstr="#f5cf00", GradientType=1);
	display: inline-block;
	margin: 0 5px;
	text-transform: uppercase;
	color: #fff;
	padding: 15px 35px;
	font-size: 1rem;
	border: none;
	border-radius: 30px;
	font-weight: 600;
}
.btn-orange:hover, .navbar-nav>li>a.btn-orange:focus,.navbar-nav>li>a.btn-orange:hover {
    background: rgb(0, 95, 184);
    background: -moz-linear-gradient( 0deg, rgba(0, 95, 184, 1) 0%, rgba(33, 171, 166, 1) 100%);
    background: -webkit-linear-gradient( 0deg, rgba(0, 95, 184, 1) 0%, rgba(33, 171, 166, 1) 100%);
    background: linear-gradient( 0deg, rgba(0, 95, 184, 1) 0%, rgba(33, 171,166, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#005fb8", endColorstr="#21aba6", GradientType=1);
    color: #fff;
}

.navbar-nav>li>a.btn-orange {
    color: #fff;
}
.find-apply {
	background: #333333;
	padding: 40px 0;
	text-align: left;
	color: #fff;
}
.find-apply h3 {
    margin-top: 0;
    font-size: 24px;
    color: #fff;
    margin-bottom: 10px;
    line-height: 1.1;
}
.find-apply .row .col-lg-6:last-child {
	padding-left: 0;
}
.or {
	position: relative;
	border-bottom: 1px dashed #ccc;
	margin-top: 40px;
}
.or::after {
	content: "or";
	position: absolute;
	left: 50%;
	width: 40px;
	height: 40px;
	background: #efefef;
	top: 50%;
	margin-top: -20px;
	margin-left: -20px;
	line-height: 40px;
	text-transform: uppercase;
	border-radius: 50%;
	border: 1px dashed #ccc;
	color: #666;
}
.browse-by {
	margin-top: 35px;
    text-align: right;
    padding-left: 17px;
}
.browse-by a, .browse-by button {
    display: inline-block;
    border: 1px solid;
    border-radius: 15px;
    position: relative;
    color: #ddd;
    padding: 10px 10px 10px 45px;
    font-size: .85em;
    text-decoration: none;
    box-shadow: 0 3px 1px -1px #000;
    margin-right: 2%;
    text-align: left;
    width: 48%;
}
.browse-by button {
    background-color: initial;
    text-align: left;
}
/* .browse-by button {
	display: inline-block;
	border: 1px solid;
	padding: 10px;
	border-radius: 15px;
	position: relative;
	color: #ddd;
	padding-left: 45px;
	font-size: 0.85em;
	margin-right: 2%;
	text-decoration: none;
	box-shadow: 0 3px 1px -1px #000;
	text-align: left;
	width: 48%;
} */
.browse-by button:last-child {
	margin-right: 0;
}
.browse-by button strong {
	display: block;
	color: #fff;
	font-weight: 600;
}
.browse-by button::before {
	content: "\f03a";
	font-family: "Font Awesome 5 Pro";
	position: absolute;
	font-size: 1.8em;
	left: 10px;
	font-weight: 300;
	top: 11px;
}
.browse-by button:hover::before {
	color: #f55200;
}
.modal-body .browse-by button {
	background: #333; 
	box-shadow: none;
	border: none;
	padding-top: 20px;
	padding-bottom: 20px;
}
.modal-body .browse-by button strong {
	display: inline-block;
	margin-left: 4px;
} 
.licence-serach .input-group .btn.btn-orange {
	padding: 0 25px;
	height: 60px;
	font-size: 40px;
	border-radius: 0 10px 10px 0;
	margin: 0;
}
.licence-serach .input-group .form-control {
	height: 60px;
	border: none;
	border-radius: 10px 0 0 10px;
	box-shadow: none;
}

.licvence-serach .input-group .btn.btn-orange {
    padding: 0 21px;
    height: 60px;
    font-size: 40px;
    border-radius: 0 10px 10px 0;
    margin: 0;
}

.licvence-serach .input-group .form-control {
    height: 60px;
    
    border-radius: 10px 0 0 10px;
    box-shadow: none;
    font-size: 14px;
}

.accordion .card {
    border: none;
    margin-bottom: 20px;
}

.card-header {
    padding: 0;
    text-align: left;
    background: none;
    border: none;
}

.card-header .card-link {
    display: block;
    padding: 13px;
    border-radius: 5px;
    position: relative;
    padding-left: 50px;
}

.card-header .card-link, .card-header .card-link.collapsed {
    color: #666;
    background: #efefef;
    text-transform: uppercase;
}

.modal-dialog .card-body {
    padding: 20px 0;
}

.modal-licence-faq {
    text-align: left;
}

.modal-dialog {
	max-width: 1000px;
	margin: 1.75rem auto;
	border-radius: 5px;
	box-shadow: 0 3px 8px rgba(0,0,0,0.5);
}

.card-header .card-link::before {
    content: "\f139";
    font-family: "Font Awesome 5 Pro";
    font-size: 24px;
    position: absolute;
    left: 15px;
    top: 50%;
    line-height: 100%;
    margin-top: -12px;
}

.card-header .card-link.collapsed::before {
    content: "\f13a";
    font-family: "Font Awesome 5 Pro";
}


.by-business-intent:hover {
    color: #ddd;
}

.by-gov-agency:hover {
    color: #ddd;
}

.middle {
    padding-top: 70px;
}

.licence-cart-div {
    margin-bottom: 50px;
}
.help-block {
	color: #ccc;
    font-style: italic;
    font-weight: normal;
    font-size: 1rem;
}
.inner-serach .help-block {
	text-align: left;
	color: #888;
	margin-top: 5px;
}
.help-block-search {
    display: block;
    margin-top: 5px;
    margin-bottom: 10px;
    color: #737373;
}
.result-block {
    border: 1px solid #ccc;
    background: #fff;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
    position: relative;
    text-align: left;
    padding-bottom: 78px;
    font-size: 14px;
}

.result-recommend {
    border: 1px dashed #ccc;
    background: #f7f7f7;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
    position: relative;
    text-align: left;
    padding-bottom: 68px;
    font-size: 14px;
}

.result-block p {
    margin: 0;
    color: #666;
}

.result-footer p strong span {
    font-weight: normal;
}

.result-footer .float-right, .result-footer .float-left {
	float: none !important;
}

.result-footer .float-right {
	margin-left: auto;
}

.result-footer {
    position: absolute;
    left: 0;
    bottom: 0px;
    width: 100%;
    background: #f7f7f7;
    border-radius: 0 0 6px 6px;
    padding: 8px 20px;
}

.result-footer {
	display: flex;
	align-items: center;
	width: 100%;
	flex-wrap: wrap;
}

.result-recommend .result-footer {
    position: absolute;
    left: 0;
    bottom: 0px;
    width: 100%;
    background: #f7f7f7;
    border-radius: 0 0 6px 6px;
    padding: 20px 20px;
}

.result-footer p {
    display: inline-block;
    vertical-align: middle;
    margin: 5px 20px 0 0;
    padding-left: 35px;
    position: relative;
}

.result-footer p strong {
    font-weight: 600;
}

.result-footer p strong {
	display: block;
}

.result-footer p::after {
    position: absolute;
    left: 0;
    font-size: 30px;
    line-height: 100%;
    height: 30px;
    width: 30px;
    top: 50%;
    margin-top: -15px;
    font-weight: 300;
    color: #999;
}

.result-footer p.fee::after {
    content: "\f2e8";
    font-family: "Font Awesome 5 Pro";
}

.result-footer p.time::after {
    content: "\f017";
    font-family: "Font Awesome 5 Pro";
}

.result-details h5 {
    color: #005eb8;
}

.search-result h3 {
    color: #666;
    text-align: left;
    margin-top: 30px;
}

.search-result h3 span {
    display: block;
    font-size: 50%;
    font-style: italic;
    margin-top: 5px;
}

.search-result h4 span {
    display: block;
    font-size: 65%;
    text-transform: none;
    color: #666;
}

.search-result h4, .documents-to-be-submitted h4, .recommend-licence h4 {
    color: #005eb8;
    text-align: left;
    margin: 30px 0 20px 0;
    font-size: 1.2rem;
    font-weight: 600;
}

.result-footer .btn, .modal-footer .btn {
    border-width: 2px;
    border-style: solid;
    border-color: #9c9c9c;
    padding: 3px 15px;
    vertical-align: middle;
    position: relative;
    margin-left: 4px;
    color: #666;
    background: #fff;
    font-size: 14px;
}

.mobile-sub-nav {
	display: none;
}

.btn.btn-add-to-cart {
    border-color: #ffae00;
    padding-left: 40px;
    min-width: 136px;
}

.btn.btn-apply {
    border-color: #16adaf
}

.btn.btn-add-to-cart::before {
    font-size: 22px;
    font-weight: bold;
    color: #ffae00;
    margin-right: 5px;
    position: absolute;
    left: 10px;
    top: 50%;
    margin-top: -11px;
    height: 22px;
    content: "\f055";
    font-family: "Font Awesome 5 Pro";
    line-height: 100%;
}

.btn.btn-add-to-cart.added {
    background: #ffae00;
    color: #fff;
}

.btn.btn-add-to-cart.added::before {
    content: "\f057";
    left: 20px;
    color: #fff;
}

.breadcrumb-main {
    background: #333;
    padding: 20px 0;
}

.breadcrumb-main .breadcrumb {
    background: none;
    margin: 0;
    padding: 0;
}

.breadcrumb-main .breadcrumb .breadcrumb-item a {
    color: #999;
    font-size: 13px;
}

.breadcrumb-main .breadcrumb .breadcrumb-item.active {
    float: none;
    display: block;
    width: 100%;
    text-align: left;
    padding: 0;
    text-transform: uppercase;
    color: #ccc;
    font-size: 30px;
    font-weight: 300;
}

.breadcrumb-main .breadcrumb .breadcrumb-item.active.single {
    margin-top: 17px;
}

.breadcrumb-item::before, .breadcrumb-item.active::after {
    display: none!important;
}

.breadcrumb-item::after {
    display: inline-block;
    padding-right: .5rem;
    color: #6c757d;
    content: "/";
    margin-left: 10px;
}

.button-center {
    margin: 40px 0;
    text-align: center;
}

.button-center .btn {
    background: #333;
    color: #fff;
    height: 54px;
    padding-left: 20px;
    min-width: 160px;
    padding-right: 20px;
    margin: 0 4px;
    border-radius: 10px;
}

.button-center .btn.btn-hazard {
    background: rgb(150, 33, 33);
}

.btn-mailing {
    display: inline-block;
    padding: 5px 10px;
    border: 2px solid #ccc;
    color: #666;
    border-radius: 5px;
    text-transform: capitalize;
    margin-left: 5px;
    text-decoration: none!important;
    margin-bottom: 20px;
}

.btn-mailing:hover {
    color: #666;
}

.documents {
    border: 1px solid #ccc;
    padding: 15px;
    border-radius: 5px;
    text-align: left;
}

.documents p {
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px dashed #ccc;
    font-size: 0.9em;
    color: #666;
}

.documents p strong {
    display: block;
    font-weight: 400;
    font-size: 1.2em;
}

.documents p:last-child {
    border: none;
    padding: 0;
}

.ant-steps-item-title::after {
    display: none;
}

.progress-steps {
    margin: 40px 0;
}

.progress-steps .wizard-flow, .progress-steps .wizard-flex {
    counter-reset: step 0;
    display: table;
    width: 100%;
    padding-top: 40px;
    padding-left: 0;
}

.progress-steps .wizard-flow .wizard-step .ant-steps-item-container, .progress-steps .wizard-flex .wizard-step .ant-steps-item-container {
    cursor: pointer;
}

.progress-steps .wizard-flow .wizard-step, .progress-steps .wizard-flex .wizard-step {
    display: table-cell;
    position: relative;
    width: 11.11%;
    color: #999;
    font-size: 0.9em;
    font-weight: 300;
}

.progress-steps .wizard-flow .wizard-step::before, .progress-steps .wizard-flex .wizard-step::before {
    border-radius: 50%;
    content: counter(step, decimal);
    counter-increment: step 1;
    display: block;
    font-size: 14px;
    height: 40px;
    left: 42%;
    line-height: 39px;
    position: sticky;
    text-align: center;
    width: 40px;
    background: #fff;
    margin-left: -20px;
    top: -45px;
    color: #999;
    font-weight: 300;
    z-index: 1;
    border: 1px dotted #aaa;
}

.progress-steps .wizard-flow .wizard-step.ant-steps-item-process:before, .progress-steps .wizard-flex .wizard-step.ant-steps-item-process:before {
    background: #005eb8;
    color: #fff;
    border: 1px solid transparent;
}

.progress-steps .wizard-flow .wizard-step.ant-steps-item-process, .progress-steps .wizard-flex .wizard-step.ant-steps-item-process {
    color: #005eb8;
}

.progress-steps .wizard-flow .wizard-step.ant-steps-item-finish:before, .progress-steps .wizard-flex .wizard-step.ant-steps-item-finish:before {
    background: #2a7a82;
    color: #fff;
    border: 2px solid #2a7a82
}

.progress-steps .wizard-flow .wizard-step::after, .progress-steps .wizard-flex .wizard-step::after {
    content: "";
    height: 2px;
    position: absolute;
    top: 20px;
    width: 100%;
    left: 0;
    background-color: #b9b9b9;
}

.progress-steps .wizard-flow .wizard-step.ant-steps-item-finish::after, .progress-steps .wizard-flex .wizard-step.ant-steps-item-finish::after {
    background: #16adaf;
}

.progress-steps .wizard-flow .wizard-step.ant-steps-item-process::after, .progress-steps .wizard-flex .wizard-step.ant-steps-item-process::after {
    background: #005eb8;
}

.progress-steps .wizard-flow .wizard-step:first-child:after, .progress-steps .wizard-flex .wizard-step:first-child:after {
    width: 50%;
    left: 50%;
}

.progress-steps .wizard-flow .wizard-step:last-child:after, .progress-steps .wizard-flex .wizard-step:last-child:after {
    width: 50%;
    right: 50%;
}

.progress-steps .wizard-flow .wizard-step.ant-steps-item-finish span, .progress-steps .wizard-flex .wizard-step.ant-steps-item-finish span {
    position: relative;
}

.progress-steps .wizard-flow .wizard-step.ant-steps-item-finish:before, .progress-steps .wizard-flex .wizard-step.ant-steps-item-finish:before {
    content: "\f058";
    font-family: "Font Awesome 5 Pro";
    font-size: 24px;
    height: 40px;
    left: 42%;
    line-height: 39px;
    position: sticky;
    text-align: center;
    width: 40px;
    background: #16adaf;
    margin-left: -20px;
    top: -45px;
    color: #fff;
    font-weight: 600;
    border: 1px solid transparent;
    z-index: 1;
    display: block;
}

.wizard-dev-form .ant-steps-item-title {
    max-width: 130px;
    white-space: normal;
    line-height: 28px;
    text-align: center;
    padding-right: 0;
}

.heading span {
    display: inline-block;
    vertical-align: middle;
    margin-top: 8px;
    font-weight: 600;
    text-transform: none;
    font-size: 1.2em;
    color: #005eb8;
}
.heading {
	display: inline-block;
	width: 100%;
	text-align: left;
	margin-bottom: 20px;
}

.heading-bg {
    background: rgb(0, 95, 184);
    background: -moz-linear-gradient(90deg, rgba(0, 95, 184, 1) 0%, rgba(33, 171, 166, 1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(0, 95, 184, 1) 0%, rgba(33, 171, 166, 1) 100%);
    background: linear-gradient(90deg, rgba(0, 95, 184, 1) 0%, rgba(33, 171, 166, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#005fb8", endColorstr="#21aba6", GradientType=1);
    color: #fff;
    text-align: left;
    padding: 14px 20px;
    border-radius: 5px;
    margin-bottom: 40px;
}

.payments .data-table .table tr td:last-child {
    width: 10%;
}

.modal-plaintext {
    margin-bottom: 0rem;
}

.icons {
    position: relative;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 14px;
    color: #666;
}

.checkbox-declaration {
    margin-bottom: 0px;
}

.form-check-label {
    padding-left: 20px;
    position: relative;
}

.browse-by-agency .find-apply {
    background: none;
    padding: 0;
    margin-top: 30px;
    margin-right: 0px;
    margin-left: 0px;
}

.browse-by-agency .licence-serach .input-group .form-control {
    border: 1px solid #ccc;
    border-right: none;
}

.browse-by-agency .nav.nav-tabs {
    margin-top: 10px;
    border: none;
}

.browse-by-agency .nav.nav-tabs li.nav-item {
    display: block;
    float: none;
    width: 100%;
    text-align: left;
    margin-bottom: 10px;
}

.browse-by-agency .nav.nav-tabs li.nav-item .nav-link {
    border: 1px solid #ddd !important;
    display: block;
    border-radius: 5px;
    color: #333;
    font-size: 14px;
    position: relative;
    padding-right: 55px;
    cursor: pointer;
}

.browse-by-agency .nav.nav-tabs li.nav-item .nav-link .sup {
    position: absolute;
    right: 15px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    top: 50%;
    margin-top: -15px;
    border: 1px solid;
    border-radius: 50%;
    font-size: 13px;
}

.browse-by-agency .nav.nav-tabs li.nav-item .nav-link strong {
    display: block;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 16px;
}

.browse-by-agency .nav.nav-tabs li.nav-item .nav-link.active, .browse-by-agency .nav.nav-tabs li.nav-item .nav-link:hover {
    background: #005eb8;
    color: #fff;
    border-color: #005eb8!important;
}

.totalLicenceFee span {
    float: right;
}

.totalLicenceFee {
    background-color: black;
}

.home-carousel .item img {
    margin: auto;
}

.home-carousel {
    background: #fff;
    padding: 40px 0;
    margin: 0 -15px;
}

.carousel-indicators li {
    border-radius: 50px;
    border: none !important;
    background: #ccc;
    height: 18px;
    width: 18px;
    margin: 0 2px !important;
}

.carousel-indicators .active {
    background-color: #d71635;
    height: 18px;
    width: 18px;
}

.carousel-indicators {
    bottom: -20px;
}

h1 {
    font-weight: 600;
    font-size: 3.2em;
    margin: 0;
}

h1 span {
    display: block;
    font-size: 24px;
    font-weight: normal;
}

.whats-new {
    padding: 40px 0;
    background: #f7f7f7;
    text-align: center;
}

.whats-new h1 {
    font-weight: 600;
    font-size: 3.2em;
    margin: 0;
}

.whats-new h3 {
    font-size: 24px;
}

.wats-new-blog-main {
    border: 1px dotted #ccc;
    padding: 5px;
    margin-top: 20px;
    background: #fff;
}

.wats-new-blog {
    height: 350px;
    position: relative;
    padding: 15px;
    padding-bottom: 50px;
}

.read-more {
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 15px;
}

.read-more span {
    text-decoration: none;
    display: inline-block;
    padding: 7px 15px;
    border-radius: 20px;
    box-shadow: 0 2px 3px -1px #666;
    color: #fff;
    font-size: 13px;
}

.read-more span:hover {
    box-shadow: 0 -2px 4px -1px #666;
}

.wats-new-blog.news-blog {
    background: url(../../assets/images/news.png) no-repeat;
}

.wats-new-blog.events-blog {
    background: url(../../assets/images/events.png) no-repeat;
}

.wats-new-blog.notice-blog {
    background: url(../../assets/images/notice.png) no-repeat;
}

.wats-new-blog ul {
    padding: 0;
    list-style: none;
}

.wats-new-blog li {
    color: #fff;
    text-align: left;
    font-size: 15px;
    margin-bottom: 15px;
    padding-left: 20px;
    position: relative;
}

.wats-new-blog li strong {
    font-weight: 600;
    display: block;
    font-size: 17px;
}

.wats-new-blog h3 {
    margin: 0;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px dotted #fff;
    color: #fff;
}

.wats-new-blog li::before {
    content: "\f111";
    font-family: "Font Awesome 5 Pro";
    font-weight: 600;
    position: absolute;
    left: 0;
    top: 8px;
    font-size: 8px;
}

.guided-simplified {
    background: #fff;
    padding: 40px 0;
}

.guided-simplified h1 {
    font-weight: 600;
    font-size: 3.2em;
    margin: 0;
}

.guided-table {
    display: table;
    width: 100%;
    margin-top: 40px;
}

.guided-table .table-cell {
    display: table-cell;
    width: 33.33%;
}

.guided-table .table-cell p strong {
    display: block;
    font-weight: 600;
    text-transform: capitalize;
}

.cell-content {
    padding: 0 40px;
    font-weight: 300;
}

.guided-details {
    margin-top: 360px;
}

.guided-table .table-cell.business-need {
    background: url(../../assets/images/business.jpg) top 55px center;
    background-size: 100%;
    background-repeat: no-repeat;
}

.guided-table .table-cell.recommended {
    background: url(../../assets/images/recommended.jpg) top 55px center;
    background-size: 100%;
    background-repeat: no-repeat;
}

.guided-table .table-cell.submission {
    background: url(../../assets/images/submission.jpg) top 55px center;
    background-size: 100%;
    background-repeat: no-repeat;
}

.faq {
    padding: 60px 0;
    background: #f7f7f7 url(../../assets/images/faq.jpg) no-repeat center center;
}

.faq h1 {
    font-weight: 600;
    font-size: 3.2em;
    margin: 0;
}

.faq-topics {
    text-align: center;
    margin: 40px 0;
}

.faq-topics ul {
    list-style: none;
    padding: 0;
    margin: 0 80px;
    display: inline-block;
    width: 360px;
    text-align: left;
}

.faq-topics ul li {
    margin-bottom: 15px;
}

.faq-topics ul li a {
    text-decoration: none;
    display: block;
    padding: 16px 20px;
    background: #1871af;
    border-radius: 10px;
    color: #fff;
    font-size: 1.2em;
}

.faq-topics ul li a:hover {
    background: #d71635;
}
 
.view-all {
    margin-top: 30px;
    display: inline-block;
    width: 100%;
}

/** Footer Styling **/

/** If there is no Footer, [dir=ltr]please delete the whole block below **/

.footer-bottom {
    display: block;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    /* padding: 20px 0; */
    color: #fff;
    font-size: 0.9em;
    background-color: #333333;
    text-align: left;
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    text-transform: uppercase;
    z-index: 3;
    white-space: nowrap;
}

.footer-bottom ul {
    padding: 0;
    list-style: none;
}

.footer-bottom ul li a {
    display: block;
    padding: 5px 0;
}

.footer-bottom ul li button {
    display: block;
    border: none;
    text-decoration: none;
    background-color: transparent;
    color: #ddd;
    padding: 5px 0;
}

.footer-bottom ul li button:hover {
    text-decoration: underline;
}

.footer-bottom ul li ul li a {
    padding: 3px 0;
}

.footer-bottom ul ul {
    padding-left: 15px;
    text-transform: none;
}

.footer-bottom a {
    color: #ddd;
}

.footer-bottom a:hover {
    text-decoration: underline;
    color: #ddd;
}

.footer-link, .footer-link:hover, .footer-link:active, .footer-link:visited {
    color: inherit;
}

.footer-bottom .footer-copy {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 20px;
    border-top: 2px solid #4c4c4c;
    margin-top: 20px;
    text-transform: none;
    color: #999;
}

.footer-bottom .footer-copy a {
    color: #999;
    margin-left: 40px;
}

@media only screen and (max-width: 767px) {
    .footer-bottom {
        text-align: center;
    }
}

/** Delete till here if there is no Footer **/

.navbar-collapse .footer-links {
    display: none;
}

.hidden-desktop {
    display: none !important;
}

/** Social Media Links Styling **/

/** If there are no Social Media Links, [dir=ltr]please delete the whole block below **/

.social-link-block {
    float: right;
    margin-top: -3px;
}

.social-link {
    background-color: #666;
    font-size: 20px;
    padding: 0;
    text-align: center;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    display: inline-block;
    width: 40px;
    border-radius: 10px;
}

.social-link-block a.social-link {
    color: #333;
    margin: 2px;
}

.social-link:hover {
    color: #000 !important;
    background-color: #fff !important;
}

@media only screen and (max-width: 767px) {
    .social-link-block {
        float: none;
        display: block;
        margin-top: 8px;
    }
}

/** Delete till here if there are no Social Media Links **/

@media only screen and (max-width: 1199px) {
    .faq-topics ul {
        margin: 0 50px;
    }
    .wats-new-blog {
        height: 400px;
        background-size: cover !important;
    }
}

@media only screen and (max-width: 991px) {
    .result-footer {
        position: static;
        display: inline-block;
        width: 100%;
        background: none;
        padding: 0;
        margin-top: 20px;
    }
    .result-block, .result-recommend {
        padding-bottom: 10px;
    }

    .browse-by-agency .nav.nav-tabs {
        background: #333;
        border-radius: 5px;
        margin-bottom: 30px;
    }
    .show {
        display: block;
    }
    .hide { 
        display: none;
    }
    .browse-by-agency .nav.nav-tabs li.nav-item {
        margin: 0;
    }
    .browse-by-agency .nav.nav-tabs li.nav-item .nav-link {
        background: none;
        color: #fff;
        border-radius: 0;
        border: none !important;
        border-bottom: 1px solid #444 !important;
    }
    .browse-by-agency .nav.nav-tabs li.nav-item:first-child .nav-link {
        border-radius: 5px 5px 0 0;
    }
    .browse-by-agency .nav.nav-tabs li.nav-item:last-child .nav-link {
        border-radius: 0 0 5px 5px;
    }
    .mobile-sub-nav {
        display: block;
        position: relative;
        text-align: left;
        padding: 15px 0 15px 30px;
        color: #d71635;
    }
    .mobile-sub-nav::before {
        content: "\f0c9";
        font-family: "Font Awesome 5 Pro";
        position: absolute;
        left: 0;
        font-size: 24px;
        line-height: 100%;
        font-weight: 400;
        top: 18px;
    }
    .mobile-sub-nav.active::before {
        content: "\f00d";
    }
    .browse-by-agency .search-result h4 {
        margin-top: 0;
    }
}

@media only screen and (max-width: 991px) {
    .faq-topics ul {
        margin: 0 20px;
        width: 300px;
    }
    .banner h2 {
        font-size: 2em;
    }
    .wats-new-blog {
        height: auto;
    }
    .guided-table .table-cell {
        background-position: top 100px center !important;
    }
    .guided-details {
        margin-top: 260px;
    }
    .browse-by {
        margin-top: 10px;
    }
    .main-content {
        padding-bottom: 0;
    }
    .footer-bottom {
        position: static;
        margin-left: -15px;
        margin-right: -15px;
        width: auto;
    }
    .social-link-block {
        float: left;
    }
}

@media only screen and (max-width: 767px) {
    .footer-top {
        display: none;
    }
    .hidden-mobile {
        display: none !important;
    }
    .hidden-desktop {
        display: block !important;
    }
    .navbar-collapse .footer-links {
        display: block;
        padding-left: 0 !important;
    }
    .footer-bottom .footer-copy {
        padding: 0;
        border: none;
        margin: 0;
        flex-direction: column;
    }
    .faq-topics ul {
        margin: 0;
        width: 100%;
    }
    .navbar .nav.navbar-nav {
        float: right;
        width: auto;
        margin-top: 8px;
        position: absolute;
        top: 5px;
        right: 54px;
    }
    .navbar .nav.navbar-nav li {
        float: none;
        display: inline-block;
        vertical-align: middle;
    }
    .navbar-brand>img {
        display: block;
        max-width: 120px;
    }
    .login .btn.btn-orange span {
        display: none;
    }
    .login .btn.btn-orange {
        padding: 0;
        border: none;
        width: 40px;
        height: 40px;
        font-size: 40px;
        line-height: 40px;
        color: #005eb8 !important;
        background: none !important;
    }
    .login .btn.btn-orange i {
        display: inline-block;
    }
    .navbar-nav>li.cart a span, .navbar-nav>li.cart button span, .navbar-nav>li.cart button.empty-cart span {
        width: 34px;
        height: 34px;
        right: -5px;
        line-height: 30px;
        font-size: 12px;
        font-weight: normal;
        top: 5px;
    }
    .navbar-nav>li.cart a, .navbar-nav>li.cart button, .login .btn.btn-orange {
        font-size: 26px;
    }
    .navbar-nav>li.cart a, .navbar-nav>li.cart button {
        padding: 10px 20px 10px 0px;
    }
    #notification .container {
        padding-right: 15px;
        position: relative;
        padding-left: 15px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    #notification strong {
        display: block;
        position: static;
        width: calc(100% - 30px);
        left: 0;
        top: auto;
        margin: 0 0 10px 0;
        float: left;
    }
    #notification-close {
        background: #d71635;
        width: 30px;
        height: 30px;
        line-height: 30px;
        top: 15px;
        border-radius: 50%;
        color: #fff;
        text-align: center;
        right: 10px;
    }
    .banner h2 {
        padding-top: 0;
        font-size: 1.3em;
        margin-top: 50px;
    }
    .banner {
        background-position: top -150px center;
        min-height: 500px;
    }
    .banner-buttons {
        margin-top: 250px;
    }
    .guided-table .table-cell {
        display: block;
        width: 100%;
    }
    .guided-table .table-cell {
        background-position: top 60px center !important;
        background-size: 220px !important;
        margin-bottom: 30px;
    }
    .guided-details {
        margin-top: 220px;
    }
    .browse-by a, .browse-by button {
        display: block;
        width: 100%;
        font-size: 0.8em;
        padding: 5px 5px 5px 40px;
        margin-bottom: 5px;
        border-radius: 10px;
        margin-right: 0px;
    }
    .banner, .find-apply, .guided-simplified, .whats-new, .faq {
        padding: 20px 15px;
    }
    .home-carousel {
        padding: 20px 0;
    }
    .whats-new h1 {
        font-size: 2em;
    }
    .guided-simplified h1 {
        font-size: 2em;
    }
    .faq h1 {
        font-size: 2em;
    }
    .footer-copy .pull-left, .footer-copy .pull-right {
        width: 100%;
        text-align: center;
    }
    .footer-copy p {
        margin: 0 0 10px;
    }
    .footer-copy .pull-right a {
        margin: 0 10px;
    }
    .banner {
        position: relative;
        padding-bottom: 50px;
    }
    .top-navbar .navbar-collapse ul li a {
        display: block;
        width: 100%;
    }
    .top-navbar .navbar-collapse ul li a {
        display: block;
        width: 100%;
        text-align: left;
        padding: 10px 25px !important;
        color: #fff !important;
        text-transform: none;
    }
    .navbar-nav {
        margin: 0;
    }
    .top-navbar .navbar-collapse ul li a {
        border-bottom: 1px solid #444;
    }
    .top-navbar .navbar-collapse ul li:last-child a {
        border: none;
    }
    .top-navbar .navbar-collapse ul li a .caret {
        display: none;
    }
    .top-navbar .navbar-collapse ul li a.dropdown-toggle {
        position: relative;
    }
    .top-navbar .navbar-collapse ul li a.dropdown-toggle::after {
        content: "\f067";
        font-family: "Font Awesome 5 Pro";
        position: absolute;
        right: 15px;
        top: 10px;
        font-size: 1.5em;
    }
    .top-navbar .navbar-collapse ul li.open a.dropdown-toggle::after {
        content: "\f068";
    }
    .navbar-nav .open .dropdown-menu {
        background: #666;
    }
    .navbar-nav>li>.dropdown-menu {
        border-radius: 0;
        box-shadow: none;
    }
    .top-navbar .navbar-collapse ul li.login-required a {
        text-align: right;
    }
    .top-navbar .navbar-collapse .navbar-right {
        display: none;
    }
    .banner p, .banner h2 {
        text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
    }
    .breadcrumb-main .breadcrumb .breadcrumb-item.active.single {
        margin-top: 0;
        margin-bottom: 10px;
    }
}

.login-container {
    background: #fff url(../../assets/images/login-bg.jpg) no-repeat center bottom;
    min-height: 730px;
    margin-right: -15px;
    margin-left: -15px;
}

.login-options {
    width: 450px;
    float: right;
    margin-top: 100px;
    min-height: 530px;
}

.login-options .panel-default>.panel-heading {
    background: none;
    box-shadow: none;
    border: none;
    padding: 0;
}

.login-options .panel-group .panel {
    border: none;
    box-shadow: none;
    border-radius: 10px 10px 0 0;
    margin: -10px 0 0 0;
    color: #fff;
}

.login-options .panel-group .panel:last-child {
    border-radius: 10px;
}

.login-options .panel-title div {
    position: relative;
    text-align: left;
    display: block;
    padding: 30px 60px 30px 70px;
    border-radius: 5px 5px;
    font-size: 17px;
    font-weight: 300;
    color: #fff;
    text-decoration: none !important;
    cursor: pointer;
}

.login-options .panel-title div::after {
    content: "\f324";
    font-family: "Font Awesome 5 Pro";
    position: absolute;
    right: 20px;
    top: 32px;
}

.login-options .panel-title div.collapsed::after {
    content: "\f324";
    font-family: "Font Awesome 5 Pro";
}

.login-options .panel-title a {
    position: relative;
    text-align: left;
    display: block;
    padding: 30px 60px 30px 70px;
    border-radius: 5px 5px;
    font-size: 17px;
    font-weight: 300;
    color: #fff;
    text-decoration: none !important;
}

.login-options .panel-title a::after {
    content: "\f324";
    font-family: "Font Awesome 5 Pro";
    position: absolute;
    right: 20px;
    top: 32px;
}

.login-options .panel-title a.collapsed::after {
    content: "\f324";
    font-family: "Font Awesome 5 Pro";
}

.panel.panel-default.corporate-login {
    background: #16ADAF;
}

.panel.panel-default.individual-login {
    background: #1186AF;
}

.panel.panel-default.licence-id {
    background: #229EE9;
}

.panel.panel-default.corporate-login .panel-title div {
    background: url(../../assets/images/corporate.png) no-repeat left 20px center;
}

.panel.panel-default.individual-login .panel-title div {
    background: url(../../assets/images/individual.png) no-repeat left 20px center;
}

.panel.panel-default.licence-id .panel-title a {
    background: url(../../assets/images/business.png) no-repeat left 20px center;
}

.login-options .panel-body {
    border: none !important;
}

.login-box {
    margin: 0 20px;
    border-top: 1px dotted;
    padding: 20px 0;
}

.login-box .form-control {
    border: none;
    height: 50px;
}

.forgot-pass-new-user {
    display: table;
    width: 100%;
}

.forgot-pass-new-user p {
    display: table-cell;
    font-size: 0.85em;
    color: #fff;
    width: 50%;
}

.forgot-pass-new-user p a {
    color: #fff;
}

.forgot-pass-new-user p a strong {
    font-weight: 600;
}

@media only screen and (max-width: 1199px) {
    .login-container {
        background-position: left -250px center;
    }
    .browse-by-agency .result-footer {
        position: static;
        display: inline-block;
        width: 100%;
        background: none;
        padding: 0;
        margin-top: 20px;
    }
    .browse-by-agency .result-block {
        padding-bottom: 10px;
    }
    .browse-by-agency .result-footer .float-left, .browse-by-agency .result-footer .float-right {
        width: 100%;
        padding: 10px 0;
    }
}

@media only screen and (max-width: 991px) {
    .login-container {
        background-position: left -470px center;
    }
}

@media only screen and (max-width: 767px) {
    .login-container {
        background: #fff;
    }
    .login-options {
        width: 100%;
        padding: 0 15px;
    }
    .login-box {
        margin: 0 10px;
    }
    .result-footer {
        position: static;
        display: inline-block;
        width: 100%;
        background: none;
        padding: 0;
        margin-top: 20px;
    }
}

@media (min-width: 768px) {
    .login-container .container {
        max-width: 750px;
    }
}

@media (min-width: 992px) {
    .login-container .container {
        max-width: 970px;
    }
}

@media (min-width: 1280px) {
    .login-container .container {
        width: 1200px;
        max-width: 1200px;
    }
}

.sticky {
	width: 100%;
	z-index: 9;
	background: #2a7a82;
	position: relative;
}
.is-sticky .navbar.navbar-expand-lg.main-nav {
	background: rgba(255,255,255,1.0)
}
.search-not-found {
    background: url(../../assets/images/searchnotfound.png) no-repeat center center;
    height: 430px;
    background-size: auto 240px;
    position: relative;
    padding-bottom: 50px;
}

.search-not-found p {
    position: absolute;
    bottom: 0;
    text-align: center;
    width: 100%;
    color: #666;
    font-weight: 600;
    font-size: 1.2em;
}

.recommend {
    background: url(../../assets/images/Banner-Bg.png) no-repeat center center;
    text-align: center;
    border-radius: 10px;
    margin: 50px 0;
}

.recommend-blog {
    padding: 40px;
}

.recommend p {
    color: #666;
    font-size: 1.3em;
    font-weight: 300;
    line-height: 100%;
    margin-bottom: 20px;
}

.recommend h4 {
    font-weight: 300;
    font-size: 1.7em;
    margin-bottom: 20px;
    text-align: center;
}

.recommend p strong {
    color: #d71635;
    font-weight: 600;
    font-size: 1.7em;
}

.recommend h5 {
    color: #d71635;
    margin-bottom: 20px;
}

.paymentRedirect {
    margin-top: 40px;
}

.overview-margin {
    margin-bottom: 0px;
}

@media only screen and (max-width: 991px) {
    .recommend {
        background-size: cover;
    }
}

@media only screen and (max-width: 767px) {
    .recommend p {
        line-height: normal;
    }
    .recommend p strong {
        display: block;
    }
    .modal-footer .btn {
        margin: 0 3px;
    }
}

/* Fixes weird date/time input layout for LTR */
.rw-widget-input {
    direction: initial;
    text-align: left;
}

/*Dashboard Css Starts*/

.dashboard {
    margin-top: 40px;
    margin-bottom: 60px;
}

.nav-tabs.main-tab {
    border: none;
    padding: 0;
    text-align: center;
    display: table;
    width: 100%;
    margin-bottom: 30px;
}

.nav-tabs.main-tab .nav-item {
    margin: 0;
    display: table-cell;
    width: 25%;
}

.nav-tabs.main-tab .nav-item a {
    display: block;
    border: 1px solid;
    border-radius: 30px;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 1.1em;
    margin: 0 15px;
    position: relative;
    padding: 5px;
    text-align: left;
}

.nav-tabs.main-tab .nav-item:first-child a {
    margin-left: 0;
}

.nav-tabs.main-tab .nav-item:last-child a {
    margin-right: 0;
}

.nav-tabs.main-tab .nav-item a span {
    line-height: 50px;
    text-align: center;
    border-radius: 50%;
    margin-right: 10px;
    height: 50px;
    width: 50px;
    display: inline-block;
    background: #000;
    color: #fff;
    font-size: 24px;
    vertical-align: middle;
}

.nav-tabs.main-tab .nav-item a#Applications-tab {
    color: #3498db;
    border-color: #3498db;
}

.nav-tabs.main-tab .nav-item a#Licences-tab {
    color: #bd4932;
    border-color: #bd4932;
}

.nav-tabs.main-tab .nav-item a#Correspondences-tab {
    color: #468966;
    border-color: #468966;
}

.nav-tabs.main-tab .nav-item a#Entities-tab {
    color: #5b3cb3;
    border-color: #5b3cb3;
}

.nav-tabs.main-tab .nav-item a#Applications-tab span {
    background: #3498db;
}

.nav-tabs.main-tab .nav-item a#Licences-tab span {
    background: #bd4932
}

.nav-tabs.main-tab .nav-item a#Correspondences-tab span {
    background: #468966
}

.nav-tabs.main-tab .nav-item a#Entities-tab span {
    background: #5b3cb3;
}
.nav-tabs.main-tab .nav-item a#Applications-tab.active {
    background: #3498db;
    color: #fff;
}

.nav-tabs.main-tab .nav-item a#Licences-tab.active {
    background: #bd4932;
    color: #fff;
}

.nav-tabs.main-tab .nav-item a#Correspondences-tab.active {
    background: #468966;
    color: #fff;
}

.nav-tabs.main-tab .nav-item a#Entities-tab.active {
    background: #5b3cb3;
    color: #fff;
}

.nav-tabs.main-tab .nav-item a#Applications-tab.active span {
    color: #3498db;
    background: #fff;
}

.nav-tabs.main-tab .nav-item a#Licences-tab.active span {
    color: #bd4932;
    background: #fff;
}

.nav-tabs.main-tab .nav-item a#Correspondences-tab.active span {
    color: #468966;
    background: #fff;
}

.nav-tabs.main-tab .nav-item a#Entities-tab.active span {
    color: #5b3cb3;
    background: #fff;
}

.dashboard-search {
    position: relative;
    margin-bottom: 20px;
}

.dashboard-search {
    position: relative;
    margin-bottom: 20px;
}

.search-box {
    color: #fff;
    text-align: left;
    display: table;
    width: 100%;
    border-radius: 0px 5px 5px 5px;
}

.search-hidden {
    display: none;
}

.search-shown {
    display: block;
}

#Applications .search-box {
    background: #3498db;
}

#Licences .search-box {
    background: #bd4932;
}

#Correspondences .search-box {
    background: #468966;
}

#Entities .search-box {
    background: #5b3cb3;
}
.search-box .search-cell {
    display: table-cell;
    padding: 15px;
    vertical-align: middle;
}

.search-box .search-cell span {
    border: 2px solid #fff;
    display: block;
    padding: 10px;
    text-align: center;
    color: #fff;
    border-radius: 10px;
    position: relative;
    padding-left: 50px;
    white-space: nowrap;
    text-decoration: none!important;
}

.search-box .search-cell:last-child {
    /* width: 215px; */
    padding-left: 0;
}

.search-box .search-cell .form-control {
    height: 50px !important;
    border: none;
    outline: none !important;
    box-shadow: none !important;
    border-radius: 10px 0 0 10px;
}

.search-box .search-cell .btn {
    background: #fff!important;
    border: none;
    margin: 0;
    font-size: 30px;
    box-shadow: none !important;
    padding: 0 10px;
    color: #999!important;
    border-radius: 0 10px 10px 0;
}

.search-cell.search-head {
    font-size: 1.5em;
    width: 30%;
}

.search-box .search-cell span::before {
    content: "\f1de";
    font-family: "Font Awesome 5 Pro";
    position: absolute;
    left: 10px;
    font-size: 24px;
    top: 5px;
}

.search-box .search-cell ::-webkit-input-placeholder {
    color: #868686;
    font-size: 14px;
    font-weight: 300;
    font-style: italic;
}

.search-box .search-cell :-moz-placeholder {
    color: #868686;
    opacity: 1;
    font-size: 14px;
    font-weight: 300;
    font-style: italic;
}

.search-box .search-cell ::-moz-placeholder {
    color: #868686;
    opacity: 1;
    font-size: 14px;
    font-weight: 300;
    font-style: italic;
}

.search-box .search-cell :-ms-input-placeholder {
    color: #868686;
    font-size: 14px;
    font-weight: 300;
    font-style: italic;
}

.advanced-search-cell .form-control {
    color: #666;
    border: none;
}

.advanced-search-fields {
    display: table;
    width: 100%;
}

.advanced-search-cell {
    display: table-cell;
    vertical-align: top;
    padding: 0 5px;
    width: 20%;
}

.advanced-search-container {
    padding: 15px;
    background: #000;
}

.advanced-search-container .button-center {
    margin: 20px 0 0 0;
}

.advanced-search-cell.calendar::after {
    right: 10px;
}

.advanced-search-container {
    padding: 15px;
    background: #3498db;
    border-radius: 5px;
}

#Licences .advanced-search-container {
    background: #bd4932;
}

#Correspondences .advanced-search-container {
    background: #468966;
}

#Entities .advanced-search-container {
	background: #5b3cb3;
}

.advanced-serach-header {
    color: #fff;
    font-size: 20px;
    padding-bottom: 20px;
    border-bottom: 2px solid #69acd1;
    margin-bottom: 20px;
    position: relative;
    text-transform: uppercase;
}

#Licences .advanced-serach-header {
    border-color: rgba(255, 255, 255, 0.30);
}

#Correspondences .advanced-serach-header {
    border-color: rgba(255, 255, 255, 0.30);
}

.advanced-search-close {
    position: absolute;
    right: 0;
    width: 30px;
    height: 30px;
    border: 1px solid #fff;
    border-radius: 50%;
    opacity: 0.7;
}

.advanced-search-close:hover {
    cursor: pointer;
    opacity: 1;
}

.inner-tabs {
    margin-top: 30px;
}

.inner-tabs .nav.nav-tabs {
    border: none;
    margin-bottom: 0px;
}

.inner-tabs .nav.nav-tabs .nav-item {
    margin: 0 10px 0 0;
}

.inner-tabs .nav.nav-tabs .nav-item a {
    border: 2px solid #9c9c9c;
    display: block;
    border-radius: 10px 10px 0 0;
    text-align: left;
    padding: 10px 10px 13px 45px;
    position: relative;
    color: #9c9c9c;
    border-bottom: none;
}

.inner-tabs .nav.nav-tabs .nav-item a span {
    display: inline-block;
    margin-left: 20px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    padding: 0 15px;
    background: #999;
    border-radius: 20px;
    min-width: 50px;
    color: #fff;
    font-weight: 300;
}

.inner-tabs .nav.nav-tabs .nav-item a#Require_Attention-tab::before {
    content: "\f0f3";
    font-family: "Font Awesome 5 Pro";
}

.inner-tabs .nav.nav-tabs .nav-item a#Drafts-tab::before {
    content: "\f15c";
    font-family: "Font Awesome 5 Pro";
}

.inner-tabs .nav.nav-tabs .nav-item a#Applications_All-tab::before {
    content: "\f0ea";
    font-family: "Font Awesome 5 Pro";
}

.inner-tabs .nav.nav-tabs .nav-item a#Due_for_Renewal-tab::before {
    content: "\f1da";
    font-family: "Font Awesome 5 Pro";
}

.inner-tabs .nav.nav-tabs .nav-item a#Unread-tab::before {
    content: "\f0e0";
    font-family: "Font Awesome 5 Pro";
}

.inner-tabs .nav.nav-tabs .nav-item a#Applications_All-tab::before {
    content: "\f0ea";
    font-family: "Font Awesome 5 Pro";
}

.inner-tabs .nav.nav-tabs .nav-item a#Licences_All-tab::before {
    content: "\f0ea";
    font-family: "Font Awesome 5 Pro";
}

.inner-tabs .nav.nav-tabs .nav-item a#Correspondences_All-tab::before {
    content: "\f0ea";
    font-family: "Font Awesome 5 Pro";
}

.inner-tabs .nav.nav-tabs .nav-item a::before {
    position: absolute;
    left: 15px;
    font-size: 22px;
    line-height: 100%;
    top: 50%;
    margin-top: -11px;
    font-weight: 600;
}

.inner-tabs .nav.nav-tabs .nav-item a.active, .inner-tabs .nav.nav-tabs .nav-item a:hover {
    color: #3498db;
    border-color: #3498db;
}

.inner-tabs .nav.nav-tabs .nav-item a.active span, .inner-tabs .nav.nav-tabs .nav-item a:hover span {
    background: #3498db;
}

#Licences .inner-tabs .nav.nav-tabs .nav-item a.active, #Licences .inner-tabs .nav.nav-tabs .nav-item a:hover {
    color: #bd4932;
    border-color: #bd4932;
}

#Licences .inner-tabs .nav.nav-tabs .nav-item a.active span, #Licences .inner-tabs .nav.nav-tabs .nav-item a:hover span {
    background: #bd4932;
}

#Correspondences .inner-tabs .nav.nav-tabs .nav-item a.active, #Correspondences .inner-tabs .nav.nav-tabs .nav-item a:hover {
    color: #468966;
    border-color: #468966;
}

#Correspondences .inner-tabs .nav.nav-tabs .nav-item a.active span, #Correspondences .inner-tabs .nav.nav-tabs .nav-item a:hover span {
    background: #468966;
}
#Entities .inner-tabs .nav.nav-tabs .nav-item a.active, #Entities .inner-tabs .nav.nav-tabs .nav-item a:hover {
	color: #5b3cb3;
	border-color: #5b3cb3;
}
#Entities .inner-tabs .nav.nav-tabs .nav-item a.active span, #Entities .inner-tabs .nav.nav-tabs .nav-item a:hover span {
	background: #5b3cb3;
}
.dashboard .table tr th, .dashboard .table tr td {
    vertical-align: middle;
}

.dashboard .action .form-control {
    color: #666;
    font-size: 1em;
}
#Licences .has-link a, .licences-details .has-link a {
	color: #bd4932
}
#Correspondences .has-link a, .correspondences-details .has-link a {
    color: #468966;
}
#Entities .has-link a, .entity-details .has-link a {
    color: #5b3cb3;
}

.back-btn {
    float: right;
    margin-top: -45px;
}

.back-btn .btn-header {
    color: #fff;
    font-weight: 300;
    position: relative;
    padding-left: 40px;
    text-decoration: none!important;
}

.back-btn .btn-header:hover {
    color: #ddd;
}

.back-btn .btn-header::before {
    content: "\f177";
    font-family: "Font Awesome 5 Pro";
    position: absolute;
    left: 0;
    font-size: 30px;
    line-height: 100%;
    top: 50%;
    margin-top: -15px;
}

.form-collapse.detailed-box-form.margin {
    margin-top: 20px;
}

.detailed-box-form #accordion .card {
    margin-bottom: 20px;
}

.detailed-box-form .card-body {
    padding: 0;
    margin-bottom: 20px;
    margin-top: -20px;
}

.detailed-box-form .form-body {
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 5px;
}

.detailed-box {
    background: #f7f7f7;
    border: 2px dotted #ddd;
    border-radius: 5px;
    margin: 55px 0 30px 0;
    padding: 55px 15px 15px 15px;
    position: relative;
}

.detailed-box::after {
    content: "\f177";
    font-family: "Font Awesome 5 Pro";
    position: absolute;
    width: 80px;
    height: 80px;
    top: -40px;
    background: #d71635;
    border: 2px dotted #fff;
    border-radius: 50%;
    left: 50%;
    margin-left: -40px;
    line-height: 60px;
    font-weight: 600;
    color: #fff;
    font-size: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.db-app-details::after {
    content: "\f4a6";
}

.db_lice_renew_details::after {
    content: "\f1da";
}

.db_corr_details::after {
    content: "\f2b6";
}

.multi-row-form {
    border-bottom: 1px solid #ddd;
    padding: 0px 15px 15px 15px;
    margin: 0 -15px 15px -15px;
}

.multi-row-form:last-child {
    border: none;
    margin: 0;
    padding: 0;
}

.multi-row-form h3 {
    color: #005eb8;
    font-size: 1.18em;
    margin-bottom: 15px;
    margin-top: 10px;
}

.form-control-plaintext .icons {
    display: block;
    padding-top: 0;
}

.modal-body .writeups {
    line-height: 1.6;
    font-size: 0.9em;
    text-align: left;
}

.detailed-box .writeups {
    margin-left: -15px;
    margin-right: -15px;
    padding: 20px;
    background: #fff;
    border-top: 2px solid #eee;
    border-radius: 10px;
    margin-top: 20px;
    font-size: 0.9em;
    text-align: left;
    color: #666;
}

.detailed-box .writeups p strong {
    display: block;
    margin-bottom: 15px;
    font-weight: 600;
}

.detailed-box.db_corr_details {
    padding-bottom: 0;
}

.detailed-box.db_corr_details .form-body {
    margin-top: 0;
}

.db-details-head {
    color: #d71635;
    text-transform: uppercase;
    font-size: 1.2em;
}

.details-box {
    background: #fff;
    border: 1px solid #ddd;
    padding: 15px 0;
    margin: 15px 0;
    border-radius: 5px;
}

.details-box h5 {
    font-weight: 600;
    font-size: 0.9em;
    color: #005eb8;
    padding: 0 20px 15px 20px;
    border-bottom: 1px solid #ddd;
}

.details-body {
    color: #666;
    font-size: 0.9em;
    padding: 20px;
    border-bottom: 1px solid #ccc;
}

.details-body p {
    margin: 0;
}

.details-footer {
    padding: 15px 20px 0px 20px;
}

.details-footer p {
    margin: 0;
    font-size: 0.9em;
    display: inline-block;
}

.details-footer p.date-fee {
    padding: 0 15px;
    border-left: 1px solid #ccc;
}

.details-footer p.date-fee:first-child {
    border-left: none;
    padding-left: 0;
}

.details-footer p.date-fee strong {
    font-size: 1.7em;
    display: block;
}

.details-footer p strong {
    font-weight: 600;
    color: #d71635;
}

.details-box {
    background: #fff;
    border: 1px solid #ddd;
    padding: 15px 0;
    margin: 15px 0;
}

.form-preview .form-group {
    margin-bottom: 0px;
}

.form-preview .form-control-plaintext {
    color: #666;
    font-weight: 600;
    font-size: 14px;
}

.form-preview .col-form-label {
    position: relative;
}

.form-preview .col-form-label::after {
    content: ":";
    position: absolute;
    right: 0;
    top: 7px;
    color: #000;
}

.clarification textarea.form-control {
    min-height: 160px;
    max-width: 100%;
}

.clarification {
    margin-bottom: 40px;
}

.additional-supporting-documents .documents-blog {
    margin-left: 0;
    margin-right: 0;
}

.card-header .card-link::before {
    content: "\f139";
    font-family: "Font Awesome 5 Pro";
    font-size: 24px;
    position: absolute;
    left: 15px;
    top: 50%;
    line-height: 100%;
    margin-top: -12px;
}

.search-result .card-header .card-link::before {
    content: "\f056";
    font-family: "Font Awesome 5 Pro";
}

.form-body {
    margin-top: 20px;
}

.col-form-label-text {
    text-align: right;
    font-size: 14px;
    color: #666;
}

.card-header .card-link.collapsed::before {
    content: "\f13a";
    font-family: "Font Awesome 5 Pro";
}

.search-result .card-header .card-link.collapsed::before {
    content: "\f055";
    font-family: "Font Awesome 5 Pro";
}

#accordion .card {
    border: none;
    margin-bottom: 10px;
}

.collapse-expand {
    text-align: right;
    margin-bottom: 20px;
}

.collapse-expand .toggle-all {
    display: inline-block;
    padding: 5px 10px;
    border: 2px solid #ccc;
    color: #666;
    border-radius: 5px;
    text-transform: capitalize;
    margin-left: 5px;
    text-decoration: none!important;
}

.collapse-expand .toggle-all.active {
    background: #16adaf;
    color: #fff;
    border-color: transparent;
}

.card-header {
    padding: 0;
    text-align: left;
    background: none;
    border: none;
}

.card-header .card-link {
    display: block;
    padding: 13px;
    border-radius: 5px;
    position: relative;
    padding-left: 50px;
}

.search-result .card-header .card-link, .search-result .card-header .card-link.collapsed {
    color: #666;
    background: #efefef;
    text-transform: uppercase;
}

.search-result-links a {
    display: block;
    text-align: left;
    padding: 10px;
    border: 1px solid #ccc;
    color: #333;
    margin-left: -20px;
    border-radius: 5px;
    margin-right: -20px;
    margin-bottom: 5px;
    text-decoration: none !important;
}

.search-result-links a:last-child {
	margin-bottom: 0px;
}
.search-result-links a:hover {
	background: #efefef;
}

.success-block {
    text-align: center;
}

.success-block h4 {
    color: #00a650;
    font-size: 36px;
    font-weight: 500;
}

.success-block h5 {
    color: #666;
}

.fas.fa-check-circle {
    color: #00a650;
    font-size: 120px;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 25px;
}

.success-block table {
    margin: 50px 0 30px;
}

.table thead tr th {
    font-size: 15px;
    font-weight: 600;
    color: #666;
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #666;
}

th, td {
    text-align: left;
}

td {
    font-size: 14px;
    color: #666;
}

.btn.btn-success {
    border-color: #666;
    padding-left: 40px;
    min-width: 136px;
    border-width: 2px;
    border-style: solid;
    padding: 3px 15px;
    vertical-align: middle;
    margin-left: 4px;
    color: #666;
    font-size: 14px;
    background: #fff;
}

.greentxt {
    color: #00a650;
}

.redtxt {
    color: #ff0000;
}

.upload-button {
    cursor: pointer;
}

.data-table .table thead th {
    border-top: none;
    text-transform: uppercase;
    font-weight: 600;
    border-bottom-color: #999;
}

.data-table .table {
    color: #666;
}

.child-row td {
    color: #999;
}

.child-row td span {
    padding-left: 20px;
}

.payment-fee {
    text-align: center;
}

tfoot {
    background: #dbdbdb;
}

tfoot td {
    padding-right: 27px!important;
    text-align: right;
    font-size: 1.2em;
}

tfoot td strong {
    margin-left: 50px;
    font-weight: 600;
}

.payments .data-table .table tr td:last-child {
    width: 10%;
}

.has-linnk a {
    color: #005eb8;
}

.choose-mode {
    text-align: left;
    margin: 40px 0;
}

.choose-mode p span {
    color: #999;
    font-size: 13px;
}

.choose-mode .btn {
    background: #dbdbdb;
    color: #999;
    padding: 15px 60px;
    position: relative;
    border-radius: 10px;
    margin-right: 4px;
}

.choose-mode .btn.active {
    background: #005eb8;
    color: #fff;
}

.choose-mode .btn.active:after {
    content: "\f058";
    font-family: "Font Awesome 5 Pro";
    position: absolute;
    right: 10px;
    font-size: 24px;
    line-height: 100%;
    top: 50%;
    margin-top: -12px;
    font-weight: 600;
}

.review-submit-blog {
    border: 1px solid #ccc;
    border-radius: 5px;
    background: #f7f7f7;
    text-align: left;
    margin: 10px 0;
}

.review-submit-blog .review-header {
    background: #fff;
    border-radius: 5px;
    padding: 20px 15px;
    position: relative;
}

.review-submit-blog .review-header h4 {
    color: #005eb8;
    text-transform: uppercase;
    font-size: 1.2em;
    margin: 0;
}

.review-details {
    padding: 15px;
}

.review-details p {
    margin: 0;
}

.review-action {
    position: absolute;
    right: 15px;
    top: 15px;
}

.review-details h5 {
    font-size: 1.1em;
}

.btn-bordered-small {
    border: 2px solid #bbb;
    padding-top: .250rem;
    padding-bottom: .250rem;
    color: #666;
    font-weight: 300;
    margin: 0 3px;
}

.btn-bordered-small-review-info {
    border: 2px solid #bbb;
    padding-top: .250rem;
    padding-bottom: .250rem;
    color: #666;
    font-weight: 300;
    margin: 0 3px;
}

.btn-bordered-small i {
    margin-right: 10px;
}

.declaration {
    text-align: left;
}

.declaration h6 {
    background: #f7f7f7;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px 15px;
    margin: 40px 0 40px 0;
}

.acknowledge {
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 20px 0;
}

.acknowledge ul {
    list-style: none;
    padding-bottom: 10px;
    padding-top: 5px;
    padding-right: 15px;
    border-bottom: 1px solid #ccc;
}

.acknowledge ul li {
    padding: 0 10px;
    line-height: 100%;
    font-size: 13px;
    font-weight: 600;
    list-style: circle;
}

.acknowledge ul li:last-child {
    border: none;
    padding-left: 0;
}

.acknowledge ul li:first-child {
    padding-left: 0;
}

.acknowledge-details {
    padding: 15px;
    font-size: 13px;
    font-weight: 300;
    line-height: 1.7;
    height: 300px;
    overflow: auto;
}

.acknowledge-details strong {
    display: block;
    font-weight: 600;
}

.acknowledge-check {
    padding: 15px;
    background: #efefef;
    border-radius: 0 0 5px 5px;
}

.acknowledge-check .label-feedback{
    padding-left: 25px;
}
.termsncond h4 {
    margin: auto !important;
    font-size: 32px;
    padding: 30px 0px;
    color: #005eb8;
    text-transform: uppercase;
    font-weight: 300
}

.termsncond-block {
    border: 1px solid #ccc;
    background: #fff;
    border-radius: 5px;
    position: relative;
    text-align: left;
    font-size: 14px;
    padding: 20px;
}

.termsncond-block {
    background-color: #e6f5fa;
    height: 400px;
    overflow: auto;
}

.termsncond-block p {
    padding-bottom: 0px;
}

.termsncond-block li {
    margin: 15px 0;
}

.termsncond-block li:empty{
    display: none;
}

.documents-blog {
    display: table;
    margin: 10px -20px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.documents-cell {
    display: table-cell;
    vertical-align: middle;
    padding: 15px;
    text-align: left;
}

.documents-blog p {
    margin: 0;
}

.documents-blog p.name {
    color: #005eb8;
    text-decoration: underline;
}

.documents-blog p.details {
    color: #666;
    font-size: 0.9em;
}

.documents-cell.document-action {
    background: #f7f7f7;
    border: 1px solid #ccc;
    width: 10%;
    text-align: center;
}

.documents-cell.document-details {
    width: 50%;
    position: relative;
    padding-left: 50px;
}

.documents-cell.document-details.active::before {
    content: "\f058";
    font-family: "Font Awesome 5 Pro";
    font-weight: 600;
    color: #00853e;
    position: absolute;
    top: 50%;
    left: 15px;
    margin-top: -11px;
    font-size: 22px;
    line-height: 100%;
}

.document-uploaded p {
    font-size: 13px;
    padding: 5px 0 5px 30px;
}

.document-uploaded p {
    font-size: 13px;
    padding: 5px 0 5px 30px;
    position: relative;
    text-overflow: ellipsis;
    width: 400px;
    white-space: nowrap;
    overflow: hidden;
}

.document-uploaded p:hover {
    color: #999;
}

.file-pdf svg {
    content: "\f057";
    font-family: "Font Awesome 5 Pro";
    position: absolute;
    left: 0;
    font-weight: 600;
    font-size: 20px;
    line-height: 100%;
    top: 50%;
    margin-top: -10px;
    color: #de1200;
    cursor: pointer
}

.dashboard-click {
    cursor: pointer;
}

.btn-action {
    border-radius: 4px;
    color: #666;
    background-color: #fff;
    margin: 2px;
    border-color: none;
}

.btn-action:hover {
    border-color: none;
    color: #666;
    background-color: #fff;
}

@media only screen and (max-width:575px) {
    .form-preview .col-form-label::after {
        display: none;
    }
}

@media only screen and (max-width: 767px) {
    .data-table .table {
        margin-bottom: 0;
        background: #fff;
    }
    .data-table .table>thead>tr>th {
        display: none;
    }
    .data-table .table>tbody>tr {
        background: #fff !important;
        display: block;
        margin: 10px 0 0px 0;
        padding-bottom: 10px;
        border-bottom: 1px solid #ddd;
        float: left;
        width: 100%;
    }
    .data-table .table>tbody>tr:last-child {
        border: none;
    }
    .data-table .table>tbody>tr>td {
        display: block;
        border: none;
        padding: 5px 15px;
        float: left;
        width: 100%;
        white-space: normal;
    }
    .child-row td span {
        padding-left: 0px;
    }
    .payments .data-table .table tr td:last-child {
        width: 100%;
    }
    tfoot td {
        text-align: left;
    }
    .termsncond h4 {
        margin: auto !important;
        font-size: 24px;
    }
}

.margin-only-40 {
    margin-top: 40px;
}

.heading-big {
    display: inline-block;
    width: 100%;
    background: rgb(0, 95, 184);
    padding: 30px 0;
    text-align: left;
}

.heading-big h2 {
    margin: 0px 0 0px 0;
    color: #fff;
    font-weight: 600;
}

.heading-big p {
    color: #fff;
    margin: 0;
    font-size: 0.95rem;
}

.questionnaire {
    margin: 30px 0;
    text-align: left;
}

.questionnaire-content {
    padding: 0 60px;
}

.questionnaire-content .bordered-box.disabled{
    background-color: #ddd;
}

.bordered-box h4, .required-licence h4 {
    font-size: 1rem;
    font-weight: 600;
    padding-bottom: 10px;
    margin-bottom: 15px;
    color: #005eb8;
    text-transform: uppercase;
    margin: 0;
    margin-top: 10px;
}

.heading-icon {
    text-align: left;
    font-size: 1.2rem;
    color: #005eb8;
    margin: 30px 0 20px 0;
    text-transform: uppercase;
    padding-left: 60px;
    min-height: 50px;
    padding-top: 12px;
}

.heading-icon.pre-request {
    background-image: url(../../assets/images/icon-prerequisite.png);
    background-repeat: no-repeat;
    background-position: left top;
    background-size: auto 50px;
}

.heading-icon.premise {
    background-image: url(../../assets/images/icon-premises.png);
    background-repeat: no-repeat;
    background-position: left top;
    background-size: auto 50px;
}

.heading-icon.business-serv {
    background-image: url(../../assets/images/icon-bizservice.png);
    background-repeat: no-repeat;
    background-position: left top;
    background-size: auto 50px;
}

.heading-icon.business-op {
    background-image: url(../../assets/images/icon-businessops.png);
    background-repeat: no-repeat;
    background-position: left top;
    background-size: auto 50px;
}

.heading-icon span {
    display: block;
    color: #666;
    text-transform: none;
    font-size: 0.85rem;
}

.heading-icon i {
    display: inline-block;
    font-size: 1.6rem;
    margin-right: 10px;
    width: 40px;
}

.pre-request~.questionnaire-content {
    display: flex;
    align-content: stretch;
    align-items: flex-start;
}

.pre-request~.questionnaire-content .col-md-4{
    padding-bottom: 5px;
    display: flex;
    align-self: stretch;
}

.required-licence {
    display: inline-block;
    background: #edf3ff;
    padding: 15px;
    border-radius: 10px;
    margin-right: 5px;
    min-height: 190px;
    vertical-align: top;
    text-align: left;
    position: relative;
    padding-bottom: 33px;
    width: 100%;
    margin-bottom: 5px;
}

.box-hidden .required-licence {
    width: 310px;
}

.box-hidden .bordered-box .required-licence {
    width: 290px;
    /* height: 210px; */
}

.box-hidden .required-licence:last-child {
    margin-right: 0;
}

.required-licence p {
    font-size: 0.9rem;
}

.required-licence .btn-licence-card {
    color: #005eb8;
    text-decoration: none !important;
    font-size: 0.9rem;
    border-bottom: 1px dashed;
    position: absolute;
    bottom: 15px;
    background: none;
    cursor: pointer;
}

.required-licence .btn-licence-card:hover {
    color: #22aca6;
}

.bordered-box {
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 15px;
}

.bordered-box.selected {
    border-color: #69acd1;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 00.2);
}

.bordered-box .box-hidden .bordered-box {
    box-shadow: none;
}

.questionnaire .separator {
    position: relative;
}

.questionnaire .separator::after {
    content: "";
    width: 10px;
    height: 10px;
    background: #22aca6;
    position: absolute;
    top: 50%;
    margin-top: -6px;
    border-radius: 50%;
    left: -12px;
}

.questionnaire .separator::before {
    content: "";
    width: 10px;
    height: 10px;
    background: #22aca6;
    position: absolute;
    top: 50%;
    margin-top: -6px;
    border-radius: 50%;
    right: -12px;
}

.check-control, .bls-radio-control {
    padding-left: 34px;
    text-align: left;
    color: #333;
    font-size: 0.9rem;
    position: relative;
    display: block;
    margin: 0;
}

.check-control.no-input {
    padding-left: 0;
}

.check-control:hover {
    cursor: pointer;
}

.check-control input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    z-index: 9999;
}

.checkbox-overlay {
    display: inline-block;
    height: 25px;
    left: 0px;
    position: absolute;
    top: 0px;
    width: 24px;
}

.checkbox-overlay::before {
    content: "\f0c8";
    font-family: "Font Awesome 5 Pro";
    font-size: 1.6rem;
    line-height: 100%;
    color: #999;
}

.check-control input[type=checkbox]:checked+.checkbox-overlay::before {
    content: "\f14a";
    font-family: "Font Awesome 5 Pro";
    font-weight: 600;
    color: #22aca6;
}

.check-control input[type=checkbox]+.checkbox-overlay.disabled::before {
    content: "\f0c8";
    font-family: "Font Awesome 5 Pro";
    font-weight: 600;
    color: #666;
}

.bls-radio-select-main {
    margin-top: 20px;
}

.bls-radio-select {
    min-height: 30px;
}

.bls-radio-control {
    font-size: 1rem;
    display: inline-block;
    min-width: 50%;
    border: 1px solid transparent;
    background: #efefef;
    padding: 15px 15px 15px 50px;
    position: relative;
    border-radius: 5px;
    margin-bottom: 5px;
}

.bls-radio-control.selected, .bls-radio-control:hover {
    border-color: #005eb8;
    color: #005eb8;
}

.bls-radio-control:hover {
    cursor: pointer;
}

.bls-radio-btn {
    opacity: 0;
    z-index: 9999;
    position: absolute;
}

/* default radio button style: unchecked */

.bls-overlay-radio {
    display: inline-block;
    height: 25px;
    left: 15px;
    position: absolute;
    top: 50%;
    width: 24px;
    margin-top: -12px;
}

.bls-overlay-radio::before {
    content: "\f111";
    font-family: "Font Awesome 5 Pro";
    font-size: 1.6rem;
    line-height: 100%;
    color: #999;
}

.bls-radio-btn:checked+.bls-overlay-radio::before {
    content: "\f192";
    font-family: "Font Awesome 5 Pro";
    font-weight: 400;
    color: #005eb8;
}

.bls-radio-btn+.bls-overlay-radio.disabled::before {
    content: "\f057";
    font-family: "Font Awesome 5 Pro";
    font-weight: 400;
    color: #666;
}

.selected .box-selection {
    margin-bottom: 15px;
}

.box-hidden {
    padding: 15px 30px;
    border-radius: 0 0 5px 5px;
}

.questionnaire .separator {
    margin-top: 50px;
}

.year-box h5 {
    font-size: 0.9rem;
    color: #005eb8;
}

.year-box p {
    border: none;
    margin-top: 15px;
}

.year-box p span {
    display: inline-block;
    width: 70px;
    height: 70px;
    background: #fff;
    border: 2px dotted #ccc;
    margin-right: 15px;
    text-align: center;
    padding-top: 15px;
    line-height: 100%;
    border-radius: 50%;
}

.year-box p span strong {
    display: block;
    font-weight: 700;
    font-size: 1.2rem;
}

.year-box {
    margin-top: 20px;
    border-top: 1px solid #ccc;
    padding-top: 20px;
    display: none;
}

.year-box p span.selected {
    background: #69acd1;
    color: #fff;
    border-color: #408fbb;
}

.bordered-box.selected>.box-hidden, .bordered-box.selected .year-box {
    display: block;
}

.box-hidden .bordered-box {
    border: none;
    padding: 0;
    border-top: 1px solid #ccc;
    border-radius: 0;
    padding-top: 20px;
    margin-bottom: 0;
}

.bordered-box .check-control strong {
    font-weight: 400;
    font-size: 1rem;
}

.bordered-box .check-control strong span {
    display: block;
    font-size: 0.9rem;
    color: #888;
    font-style: italic;
}

.bordered-box.selected .check-control strong, .bordered-box .check-control:hover {
    color: #005eb8;
    cursor: pointer;
}

.bordered-box.selected .box-hidden .bordered-box .check-control strong {
    color: #333;
}

.bordered-box.selected .box-hidden .bordered-box.selected .check-control strong, .bordered-box.selected .box-hidden .bordered-box .check-control:hover strong {
    color: #005eb8;
}

.box-hidden h5 {
    font-size: 0.9rem;
    font-weight: 400;
}

.required-licence:last-child {
    margin: 0;
}

.bls-radio-control.selected~.box-hidden {
    display: block;
}

/* .box-hidden .box-hidden {
    padding-bottom: 0;
} */

.guided-journey-banner {
    height: 437px;
    background: url(../../assets/images/GuidedJourneyBanner-5a.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
}

.guided-journey-banner::after {
    background: url(../../assets/images/bg-repeat.jpg);
    background-repeat: repeat-x;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.guided-journey-banner .tagline {
    color: #fff;
    margin-top: 60px;
    display: inline-block;
    font-size: 3rem;
    font-weight: 300;
}

.guided-journey-banner .tagline strong {
    display: block;
    font-weight: 600;
    color: #ffae00;
    line-height: 100%;
}

.inner-serach {
    width: 700px;
    margin: auto;
    position: absolute;
    left: 50%;
    margin-left: -350px;
    bottom: 40px;
}

.modal-body .inner-serach {
    position: static;
    margin: 0 auto;
}

.inner-serach .licence-serach .input-group .form-control {
    border: 2px solid #ddd;
    border-right: none;
}

.inner-serach .licence-serach .input-group .btn.btn-orange {
    font-size: 1rem;
}

.inner-serach .licence-serach .input-group .btn.btn-orange i {
    font-size: 2rem;
}

.guided-journey {
    max-width: 900px;
    margin: 0 auto 60px auto;
}

.guided-box {
    border: 1px solid #005eb8;
    border-radius: 5px;
    padding: 20px;
    margin: 0 0 20px 0;
    text-align: left;
    border-top: 3px solid #005eb8;
    background: #ebf4ff;
    padding-right: 80px;
    position: relative;
}

[dir=ltr] .guided-box::after {
    content: attr(stickerTitle);
    position: absolute;
    right: 15px;
    height: 70px;
    width: 70px;
    border-radius: 50%;
    background: #16adaf;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 100%;
    text-align: center;
    font-size: 0.9rem;
    font-weight: 600;
    top: 15px;
}

.guided-box-select .guided-box {
    background: none;
    border: 1px solid #ccc;
    padding-right: 45px;
    position: relative;
    margin-bottom: 20px;
    margin-top: 0;
    display: block;
    text-decoration: none!important;
}

.guided-box-select .guided-box:hover {
    background: #f1f5f9;
}

.guided-box-select .guided-box::after {
    content: "\f061";
    font-family: "Font Awesome 5 Pro";
    position: absolute;
    right: 20px;
    top: 50%;
    height: 26px;
    line-height: 100%;
    font-size: 26px;
    margin-top: -15px;
    color: #005eb8;
    background: none;
    font-weight: 400;
    width: auto;
}

.guided-journey h4 {
    color: #666;
    font-size: 1.2rem;
    margin-top: 30px;
    text-align: left;
}

.guided-box h4 {
    color: #005eb8;
    margin: 0;
    font-weight: 600;
}

.guided-box .button-center {
    margin: 0;
}

.guided-box .btn, .homepage-tagline .btn {
    border-radius: 40px;
    min-width: 230px;
    padding-left: 50px;
    padding-right: 50px;
}

.guided-box .btn {
    margin-left: 0;
}

.btn.btn-blue {
    background: #005fb8;
    border: 2px solid transparent;
}

.btn.btn-green {
    background: #16adaf;
    border: 2px solid transparent;
}

.btn.btn-blue:hover {
    background: #fff!important;
    border: 2px solid #005fb8!important;
    color: #005fb8!important
}

.btn.btn-green:hover {
    background: #fff!important;
    border: 2px solid #16adaf!important;
    color: #16adaf!important
}

.guided-box-select {
    display: none;
}

.guided-box-select.active {
    display: block;
}

.guided-box-select .guided-box h4 {
    font-weight: 400;
    margin-top: 0;
}

.guided-box p {
    color: #666;
}

.guided-box-select .guided-box p:last-child {
    margin: 0;
}

.inner-serach .tagline {
    font-size: 3rem;
    font-weight: 300;
    color: #666;
    margin-bottom: 40px;
}

.inner-serach .tagline strong {
    display: block;
    line-height: 100%;
    font-weight: 600;
    color: #ffae00;
}

.not-found {
    padding-top: 260px;
    background: url(../../assets/images/no-match.png) no-repeat center top;
    max-width: 700px;
    margin: 60px auto;
}

.not-found p {
    font-size: 1.2rem;
}

.not-found p strong {
    font-weight: 600;
    color: #005eb8;
}

.tips {
    border-top: 1px solid #ccc;
    padding-top: 20px;
    padding-left: 90px;
    position: relative;
}

.tips::before {
    content: "\f672";
    font-family: "Font Awesome 5 Pro";
    position: absolute;
    left: 0;
    top: 20px;
    right: 15px;
    height: 70px;
    width: 70px;
    border-radius: 50%;
    background: #16adaf;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 100%;
    text-align: center;
    font-size: 2rem;
    font-weight: 300;
}

.tips p {
    position: relative;
    text-align: left;
    margin: 0;
    padding-left: 15px;
    color: #666;
    font-size: 0.9rem;
}

.tips p::before {
    content: "";
    width: 6px;
    height: 6px;
    background: #999;
    position: absolute;
    left: 0;
    top: 10px;
    border-radius: 50%;
}

.tips h5, .refine .tips h5 {
    text-align: left;
    font-weight: 600;
    font-size: 1rem;
    color: #005eb8;
}

.refine h5 {
    font-size: 1.1rem;
    text-align: left;
    font-weight: 400;
    color: #666;
}

.refine {
    margin-top: 40px;
}

@media only screen and (max-width:991px) {
    .modal-body .browse-by {
        margin-top: 40px;
    }
}

@media only screen and (max-width:767px) {
    .homepage-tagline {
        padding: 20px 15px;
    }
    .banner {
        background-size: auto 220px;
        min-height: 220px;
    }
    .modal-body .inner-serach {
        width: 100%;
    }
    .inner-serach .tagline {
        font-size: 2rem;
    }
    .or {
        margin-bottom: 40px;
    }
    .homepage-tagline .btn {
        width: 100%;
    }
    .licence-serach .input-group .btn.btn-orange {
        padding: 0 10px;
    }
    .guided-box .btn, .homepage-tagline .btn {
        border-radius: 40px;
        min-width: 100%;
        padding-left: 40px;
        padding-right: 40px;
    }
    .guided-journey-banner .inner-serach {
        width: 100%;
        position: static;
        margin: 65px 0px 0 0px;
        padding: 15px;
    }
    .guided-journey-banner {
        background-size: auto 300px;
        height: 300px;
    }
    .guided-box {
        padding-right: 20px;
        padding-top: 100px;
    }
    /*
.guided-box {
    padding-right: 20px;
    padding-top: 50px;
}*/
.guided-box::after {
        left: 50%;
        right: auto;
        margin-left: -35px;
        top: 20px;
    }
    /*.guided-box::after {
    left: 0;
    margin-left: 0;
    top: 0;
    width: 100%;
    right: 0;
    line-height: 100%;
    height: auto;
    padding: 10px;
    border-radius: 3px 3px 0 0;
}*/
.guided-journey-banner .tagline {
        font-size: 2rem;
    }
    .guided-box-select .guided-box {
        padding-top: 20px
    }
    .guided-box-select .guided-box::after {
        margin-left: 0;
        left: auto;
    }
    .heading-big h2 {
        font-size: 1.4rem;
    }
    .heading-big p {
        font-size: 0.9rem;
    }
}

.documents p a {
    font-weight: 600;
    font-size: 0.8rem;
    text-decoration: underline;
    color: #666;
    position: relative;
}

.cus-tooltip {
    position: absolute;
    width: 300px;
    padding: 15px;
    color: #fff;
    left: -14px;
    top: 28px;
    font-size: 0.8rem;
    font-weight: 400;
    border-radius: 16px;
    z-index: 9;
    background: rgba(0, 94, 184, 0.97);
    display: none;
}

.cus-tooltip span {
    display: block;
    margin: 3px 0;
}

.documents p a:hover .cus-tooltip {
    display: block;
}

.cus-tooltip::before {
    content: "";
    position: absolute;
    left: 25px;
    top: -10px;
    border-bottom: 10px solid #005eb8;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
}

@media only screen and (max-width:1279px) {
    .required-licence {
        width: 320px;
    }
    .box-hidden .required-licence {
        width: 290px;
    }
    .box-hidden .bordered-box .required-licence {
        width: 268px;
        /* height: 210px; */
    }
}

@media only screen and (max-width:1199px) {
    .required-licence {
        width: 100%!important;
        height: auto!important;
        margin-bottom: 10px;
    }
}

@media only screen and (max-width:1767px) {
    .box-hidden .box-hidden {
        padding: 0;
    }
    .questionnaire-content {
        padding: 0 0px;
    }
}
.align-text-left{
    text-align: left;
}

.alert-primary .inline-link {
    color:inherit;
    text-decoration: underline !important;
    font-weight: bold;

}

.bls-btm-navbar{
    padding: 0px 15px;
}

.bls-btm-navbar>div{
    width: 100%;
}

.bls-btm-navbar>div>div{
    left: 0px;
}

.stick-row {
    width: 100%;
}

.sticky-bar{
    background-color: #FFFFFF;
    border-bottom: 1px solid #ddd;
    left: 1px;
}

.sticky-row{
    align-items: center;
}

.sticky-left{
    display: flex;
    justify-content: flex-start;
}

.sticky-right{
    display: flex;
    justify-content: flex-end;
}

.input-group>.custom-file .custom-file-input:focus~.custom-file-label, .input-group>.custom-select:focus, .input-group>.form-control:focus{
    z-index: 1;
}

.search-licence-link {
    color: #0772b8;
    cursor: pointer;
    display: inline;
}

.search-licence-link:hover {
    color: #0772b8;
    text-decoration: underline;
}

.link-margin-left {
    margin-left: 5px;
}

.button-mail {
     text-align: center; 
}

.table-add-row {
    float: right;
}

.table-requirement-label {
    float: left;
    text-align: left;
}
.div-modal-body {
  text-align: left;
}

.div-modal-body .label-error {
  text-align: left;
}

.div-pre-req-desc {
padding: 0;
margin: 10px;
}

.div-pre-req-show-hide.div-pre-req-show {
margin: 0 10px;
display: inline-block;
}
.btn.btn-add-to-cart {
padding-left: 40px!important;
}
[dir=ltr] .prereq-first-licence-result-footer{ padding-bottom:7px!important}

@media only screen and (max-width: 575.98px) {
    .button-center .btn {
      min-width: 100%;
    }
    .modal-body {
      padding-left: 20px;
      padding-right: 20px;
    }
    .heading-icon.pre-request {
      background-size: auto 57px !important;
      height: 60px;
    }
  }
  @media only screen and (max-width: 767px) {
    .modal-body,
    .modal-header {
      padding-left: 15px;
      padding-right: 15px;
    }
    .guided-journey-banner .tagline {
      padding-left: 1rem;
      padding-right: 1rem;
      margin-top: 30px;
    }
    .guided-box .button-center .btn {
      height: auto !important;
    }
    .questionnaire .col .row {
      margin-left: 0;
      margin-right: 0;
    }
    .dashboard .table tr td,
    .dashboard .table tr th {
      display: block;
    }
    #mobileBtnContainer .ant-btn.ant-dropdown-trigger.btn-action {
      width: 100%;
      margin-bottom: 3px;
    }
    .pagination-group {
      width: 100%;
    }
    .card-container.card {
      width: 100%;
    }
    .user-profile {
        width: 100%;
        position:static;
        height: 170px;
        left: 0;
        background: rgb(33,171,166);
        background: -moz-linear-gradient(90deg, rgba(33,171,166,1) 0%, rgba(0,95,184,1) 100%);
        background: -webkit-linear-gradient(90deg, rgba(33,171,166,1) 0%, rgba(0,95,184,1) 100%);
        background: linear-gradient(90deg, rgba(33,171,166,1) 0%, rgba(0,95,184,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#21aba6", endColorstr="#005fb8", GradientType=1);
        top: 98px;
        color: #fff;
        padding: 40px 0;
        font-weight: 300;
        display: block;
    }
  }