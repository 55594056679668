/*************** Styling: Title ***************/


.body-title {
    min-height: 77px;
    background-color: #FFFFFF;
    border-bottom: 1px solid #E7EAEC;
    z-index: 2;
    flex-direction: column;
}

.body-title .col{
    min-height: fit-content;
}

.title-children{
    font-size: 14px;
    font-weight: 500;
}

.body-title .title-children-item{
    font-size: 14px;
    font-weight: 500;
}

.body-title .title-children-item-label{
    font-size: 14px;
    font-weight: 700;
}

.body-title .title-children-item-value{
    font-size: 14px;
    font-weight: 700;
}

.body-title .title-custom{
    height: 100%;
}

.body-title .title-actions{
    justify-content: flex-end;
    display: flex;
    align-items: flex-end;
}

.body-title .title-actions button{
    margin: 1px;
}

.body-title .title-actions .ant-btn.ant-dropdown-trigger{
    display: flex;
    align-items: center;
}

.body-title .title-actions .anticon.anticon-down{
    display: flex;
    padding-top: 8px;
}

.body-title-text {
    display:flex;
    align-items: center;
    padding: 20px;
    padding-left: 20px;
    font-size: 26px;
    font-weight: 100;
}

@media only screen and (max-width: 992px) {
    .body-title-text {
        text-align: center;
        padding: 20px 10px;
    }

}

.title-expand {
    margin-left: 220px;
}

.title-hide {
    margin-left: 0px;
}


/*************** Styling for First Layout ***************/


/*************** Styling for Second Layout ***************/
.second.body-title-text {
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin: 0px;
    padding: 20px;
    padding-left: 15px;
    font-size: 26px;
    font-weight: 100;
}


/*************** Styling for Mobile View ***************/
@media only screen and (max-width: 992px) {

    .first.body-title-text {
        display:flex;
        align-items: center;
        /* padding: 15px 10px !important; */
        /* font-size: 22px !important;
        font-weight: 300 !important; */
        /* margin-left: -25px; */
    }


    .second.body-title-text {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        margin: 0px -15px;
        padding: 15px 10px;
        font-size: 22px;
        font-weight: 300;
        border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    }

    .body-title > .col{
        align-items: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .body-title .title-children-item{
        font-size: 13px;
        font-weight: 500;
        white-space: nowrap;
    }
    
    .body-title .title-children-item-label{
        display: none;
    }

    .body-title .title-children-item-label.title-show-mobile{
        font-size: 13px;
        font-weight: 700;
        white-space: nowrap;
        display: inline-flex;
    }

    .body-title .title-children-item-value{
        font-size: 13px;
        font-weight: 700;
        white-space: nowrap;
    }

    .body-title .title-children{
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    .body-title .title-children > .col{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .body-title .title-actions{
        justify-content: center;
        display: flex;
        align-items: flex-end;
    }

    .title-with-children .title-text {
        display: none;
    }
}